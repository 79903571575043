import React from 'react';

import { illusEscalier } from 'src/Assets';
import Loader from 'src/Components/Loader';
import { FETCH_STATUS } from 'src/Redux/Types';

import styles from './Callback.module.scss';
import { Props } from './Callback.types';

const Callback = ({ fetchStatus }: Props) => (
  <div className={styles.container}>
    <div className={styles.modal}>
      {fetchStatus === FETCH_STATUS.REJECTED ? (
        <>
          <p className={styles.text}>
            Impossible d'initialiser
            <br /> l'application !
          </p>
          <img src={illusEscalier} alt="perimeter-error" />
        </>
      ) : (
        <>
          <p className={styles.text}>
            Initialisation de votre
            <br /> application en cours
          </p>
          <Loader heightInRem={4} />
        </>
      )}
    </div>
  </div>
);

export default Callback;
