import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getNumberOfOffers } from 'src/Redux/Offers/Selectors';
import { fetchOffers } from 'src/Redux/Offers/Thunks';
import { searchActions } from 'src/Redux/Search/Slice';

import MainPageContent from './MainPageContent.component';

const EnhancedMainPageContent = () => {
  const dispatch = useDispatch();
  const numberOfResults = useSelector(getNumberOfOffers);

  const onResultPerPageUpdate = useCallback(
    (resPerPage: number) => {
      dispatch(searchActions.setResultsPerPage(resPerPage));
      dispatch(searchActions.setCurrentPage(0));
    },
    [dispatch]
  );

  const onSearch = useCallback(() => {
    dispatch(fetchOffers());
  }, [dispatch]);

  return (
    <MainPageContent
      numberOfResults={numberOfResults}
      onResultPerPageUpdate={onResultPerPageUpdate}
      onSearch={onSearch}
    />
  );
};

export default EnhancedMainPageContent;
