import React, { useEffect } from 'react';
import { Route, Router, Switch, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import useAuth from 'src/Hooks/Authentication/useAuth';
import { setupAPI } from 'src/Services/API/APIConfigurator';
import { security } from 'src/Services/API/Interceptors';
import history from 'src/Utils/history';

import './App.css';

import PrivateRoute from './Components/PrivateRoute';
import Callback from './Containers/Callback';
import Login from './Containers/Login';
import MainPage from './Containers/MainPage';
import OfferCommentModal from './Containers/MainPage/Modal/OfferCommentModal';
import SearchSaveModal from './Containers/MainPage/Modal/SearchSaveModal';
import OfferModal from './Containers/Modals/OfferModal';
import useInitializeAnalytics from './Hooks/Analytics/useInitializeAnalytics';
import { LocationState, ModalRoute } from './Hooks/Modal';

const modalRouteMap: ModalRoute[] = [
  { route: 'offer/:offerId', component: OfferModal },
  { route: 'editSearch', component: SearchSaveModal },
  { route: 'commentOffer', component: OfferCommentModal },
];

function ModalSwitch() {
  const location = useLocation<LocationState>();
  const locationState = location.state;
  const background = locationState?.background;
  const history = useHistory();

  if (
    !background &&
    modalRouteMap.some(
      modalRoute => modalRoute.route === location.pathname && !modalRoute.backgroundIsOptional
    )
  ) {
    history.push('/');
  }

  return (
    <>
      <Switch location={background || location}>
        <Route exact path="/login" component={Login} />
        <Route exact path="/callback" component={Callback} />
        <PrivateRoute path="/" component={MainPage} />
      </Switch>

      {modalRouteMap.map(modalInfo => (
        <PrivateRoute
          key={modalInfo.route}
          path={`/${modalInfo.route}`}
          children={<modalInfo.component modalParameters={locationState?.modalParameters} />}
        />
      ))}
      <ToastContainer position="bottom-right" />
    </>
  );
}

function App() {
  const { getAccessTokenSilently, logout } = useAuth();
  security.setAccessTokenSilently(getAccessTokenSilently);
  security.setLogout(logout);

  useInitializeAnalytics();
  useEffect(() => setupAPI(), []);
  return (
    <div className="App">
      <Router history={history}>
        <ModalSwitch />
      </Router>
    </div>
  );
}

export default App;
