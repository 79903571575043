export enum MutationKeys {
  locationSuggestions = 'locationSuggestions',
  markOfferAsRead = 'markOfferAsRead',
  saveConsultantSearch = 'saveConsultantSearch',
  updateSavedSearch = 'updateSavedSearch',
  deleteSavedSearch = 'deleteSavedSearch',
  updateOfferComment = 'updateOfferComment',
  setConsultantFavoriteSearch = 'setConsultantFavoriteSearch',
  exportSearch = 'exportSearch',
}

export enum QueryKeys {
  fetchSeenOffers = 'fetchSeenOffers',
  fetchOfferComment = 'fetchOfferComment',
  fetchConsultantFavorites = 'fetchConsultantFavorites',
  fetchConsultantFavorite = 'fetchConsultantFavorite',
  fetchNafs = 'fetchNafs',
}
