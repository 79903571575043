import React from 'react';

import { logo } from 'src/Assets';
import BasicButton from 'src/Components/Buttons/BasicButton';

import styles from './Login.module.scss';
import { Props } from './Login.types';

const Login = ({ onLoginClick, loading }: Props) => (
  <div className={styles.container}>
    <div className={styles.modal}>
      <img src={logo} alt="flash-logo" className={styles.logo} />
      <p className={styles.title}>
        bienvenue
        <br />
        sur la pige
      </p>
      <p className={styles.description}>
        votre service de consultation
        <br />
        d'offres et d'annonces
      </p>
      <BasicButton
        style={styles.buttonContainer}
        titleStyle={styles.buttonTitle}
        disabled={loading}
        onClick={onLoginClick}
        title="se connecter"
      />
    </div>
  </div>
);

export default Login;
