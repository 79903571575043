import { useQuery } from 'react-query';

import { OffersService } from 'src/Services/API';

import { QueryKeys } from './types';

export const useFetchOfferComment = ({ offerId }: { offerId: string }) => {
  return useQuery(
    [QueryKeys.fetchOfferComment, offerId],

    () => {
      return OffersService.offersControllerOfferComments({ id: offerId });
    },
    {
      cacheTime: 0,
      staleTime: 0,
      enabled: offerId !== '' && offerId !== undefined,
      refetchOnWindowFocus: false,
    }
  );
};
