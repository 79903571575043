import { Chip } from '@mui/material';
import { AutoSuggest } from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFetchCompanySuggestions } from 'src/Hooks/useFetchCompanySuggestions';
import { useFetchQualificationSuggestions } from 'src/Hooks/useFetchQualificationSuggestions';
import { getSelectedCriterias } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { Criteria, CRITERIA_TYPE } from 'src/Redux/Search/Types';
import { toFetchStatus } from 'src/Services/ReactQuery';
import { getTypeLabel } from 'src/Utils/transformers';

import styles from './CriteriaSearchInput.module.scss';

const CriteriaSearchInput = () => {
  const _selectedCriterias = useSelector(getSelectedCriterias);

  const [keyword, setKeyword] = useState('');
  const selectedCriterias = useMemo(
    () =>
      _selectedCriterias
        .filter(
          criteria =>
            criteria.type === CRITERIA_TYPE.QUALIFICATIONS ||
            criteria.type === CRITERIA_TYPE.COMPANIES ||
            criteria.type === CRITERIA_TYPE.KEYWORD
        )
        .map(criteria => ({
          key: criteria.criteria.key,
          value: criteria.criteria.value,
          type: criteria.type,
        }))
        .flat(),

    [_selectedCriterias]
  );

  const fetchQualificationsCriterias = useFetchQualificationSuggestions();

  const fetchCompanyCriterias = useFetchCompanySuggestions();

  const dispatch = useDispatch();

  const updateSelectedCriterias = useCallback(
    (type: CRITERIA_TYPE, criterias: Criteria[]) => {
      dispatch(searchActions.updateSelectedCriterias({ type, criterias }));
    },
    [dispatch]
  );

  return (
    <div className={styles.container}>
      <AutoSuggest
        id="criteriaCriteria"
        placeholder={'ex : qualification, nom de société'}
        value={selectedCriterias}
        onChange={searchResult => {
          updateSelectedCriterias(
            CRITERIA_TYPE.QUALIFICATIONS,
            searchResult.filter(result => result.type === CRITERIA_TYPE.QUALIFICATIONS)
          );

          updateSelectedCriterias(
            CRITERIA_TYPE.COMPANIES,
            searchResult.filter(result => result.type === CRITERIA_TYPE.COMPANIES)
          );

          updateSelectedCriterias(
            CRITERIA_TYPE.KEYWORD,
            searchResult.filter(result => result.type === CRITERIA_TYPE.KEYWORD)
          );
        }}
        searchResults={[
          ...(fetchQualificationsCriterias.data ?? []),
          ...(fetchCompanyCriterias.data ?? []),
          ...[{ type: CRITERIA_TYPE.KEYWORD, key: CRITERIA_TYPE.KEYWORD, value: keyword }],
        ]}
        onSearch={searchText => {
          setKeyword(searchText);
          fetchQualificationsCriterias.mutate(searchText);
          fetchCompanyCriterias.mutate(searchText);
        }}
        fetchStatus={toFetchStatus(fetchQualificationsCriterias, fetchCompanyCriterias)}
        keyValueExtractor={criteria => ({
          key: criteria.key,
          value: criteria.value,
        })}
        minLengthToSearch={1}
        showSelected
        className={styles.autosuggest}
        limitTags={2}
        groupBy={getTypeLabel}
        inputClassName={styles.textInput}
        renderTags={(value, getTagProps) => {
          return value.map((criteria, index) => {
            const { className, ...rest } = getTagProps({ index });
            return (
              <Chip
                label={
                  criteria.value.length > 5
                    ? `${criteria.value.substring(0, 5)}...`
                    : criteria.value
                }
                {...rest}
                className={classnames(className, styles.chip)}
              />
            );
          });
        }}
      />
    </div>
  );
};

export default CriteriaSearchInput;
