import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../RootReducer';

import { SearchState } from './Types';

export const getSearchState = (state: RootState) => state.search;

export const getStartDate = createSelector([getSearchState], (state: SearchState) => {
  return state.startDate;
});

export const getEndDate = createSelector([getSearchState], (state: SearchState) => state.endDate);
export const getBusinessGroup = createSelector(
  [getSearchState],
  (state: SearchState) => state.businessGroup
);

export const getSelectedCriterias = createSelector(
  [getSearchState],
  (state: SearchState) => state.selectedCriterias
);

export const getCurrentPage = createSelector(
  [getSearchState],
  (state: SearchState) => state.currentPage
);

export const getResultsPerPage = createSelector(
  [getSearchState],
  (state: SearchState) => state.resultsPerPage
);

export const getOrderBySettings = createSelector(
  [getSearchState],
  (state: SearchState) => state.orderBySettings
);

export const getPredefinedTimeRange = createSelector(
  [getSearchState],
  (state: SearchState) => state.predefinedTimeRange
);

export const getHideIntermediaryOffers = createSelector(
  [getSearchState],
  (state: SearchState) => state.hideIntermediaryOffers
);
