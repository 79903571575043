import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { AuthProvider } from './Auth';
import { ReduxProvider } from './Redux';

const queryClient = new QueryClient();

const Providers = ({ children }: { children: React.ReactElement }) => {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <ReduxProvider>{children}</ReduxProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
};

export default Providers;
