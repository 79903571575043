import { AppState, Auth0Provider as RawAuth0Provider } from '@auth0/auth0-react';
import React from 'react';

import history from 'src/Utils/history';

const onRedirectCallback = (appState: AppState) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname);
};

export const Auth0Provider = ({ children }: { children: React.ReactNode }) => {
  return (
    <RawAuth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE ?? ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
      redirectUri={`${process.env.REACT_APP_AUTH0_REDIRECT_URI}/callback` ?? ''}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </RawAuth0Provider>
  );
};
