import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import useAuth from 'src/Hooks/Authentication/useAuth';
import { useFetchSeenOffers } from 'src/Hooks/useFetchSeenOffers';
import { useMarkOfferAsRead } from 'src/Hooks/useMarkOfferAsRead';
import {
  getCurrentOffer,
  getCurrentOfferFetchStatus,
  getCurrentOfferId,
  getCurrentOfferIndex,
  getNumberOfOffers,
  getOffers,
  getOffersFetchStatus,
  getSameClientOffers,
  getSameClientOffersFetchStatus,
} from 'src/Redux/Offers/Selectors';
import { offersActions } from 'src/Redux/Offers/Slice';
import { fetchOffer, fetchOffers } from 'src/Redux/Offers/Thunks';
import { getCurrentPage, getResultsPerPage } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { aggregateFetchStatuses, transformOfferDetail } from 'src/Utils/transformers';

import OfferDetail from './OfferDetail.component';
import { OwnProps } from './OfferDetail.types';

const EnhancedOfferDetail = (otherProps: OwnProps) => {
  const dispatch = useDispatch();
  const urlElements = useRouteMatch<{ offerId?: string }>('/offer/:offerId');
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated && urlElements?.params.offerId !== undefined) {
      dispatch(fetchOffer(urlElements.params.offerId));
    }
  }, [dispatch, urlElements?.params.offerId, isAuthenticated]);
  const { mutate } = useMarkOfferAsRead();
  const fetchSeenOffers = useFetchSeenOffers();

  const currentOffer = useSelector(getCurrentOffer);
  const offersCount = useSelector(getNumberOfOffers);
  const currentOfferIndex = useSelector(getCurrentOfferIndex);
  const canGoThroughOffers = currentOfferIndex !== undefined;
  const [offerNumber, setOfferNumber] = useState(
    (currentOfferIndex ?? 0) + 1 + useSelector(getCurrentPage) * useSelector(getResultsPerPage)
  );

  const offerFetchStatus = aggregateFetchStatuses(
    useSelector(getCurrentOfferFetchStatus),
    useSelector(getOffersFetchStatus)
  );
  const currentOfferId = useSelector(getCurrentOfferId);

  const markOfferAsRead = useCallback(() => {
    if (currentOfferId !== undefined && !fetchSeenOffers.data?.offerIds.includes(currentOfferId)) {
      mutate(currentOfferId);
    }
  }, [currentOfferId, fetchSeenOffers.data?.offerIds, mutate]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(markOfferAsRead, [currentOfferId]);

  const offers = useSelector(getOffers);
  const selectedPage = useSelector(getCurrentPage);
  const pageAmount = Math.ceil(useSelector(getNumberOfOffers) / useSelector(getResultsPerPage));
  const resultsPerPage = useSelector(getResultsPerPage);

  const onRetry = useCallback(() => {
    dispatch(fetchOffer(currentOfferId ?? ''));
  }, [currentOfferId, dispatch]);

  const goToNextOffer = useCallback(() => {
    if (currentOfferIndex === undefined || offerNumber === offersCount) {
      return;
    }

    if (currentOfferIndex === offers.length - 1) {
      if (selectedPage !== pageAmount) {
        dispatch(searchActions.setCurrentPage(selectedPage + 1));
        dispatch(fetchOffers(0));
      }
    } else {
      const newId = offers[currentOfferIndex + 1].id;
      dispatch(fetchOffer(newId));
      dispatch(offersActions.setCurrentOfferId(newId));
    }
    setOfferNumber(offerNumber + 1);
  }, [currentOfferIndex, dispatch, offerNumber, offers, offersCount, pageAmount, selectedPage]);

  const goToPreviousOffer = useCallback(() => {
    if (currentOfferIndex === undefined || offerNumber === 1) {
      return;
    }

    if (currentOfferIndex === 0) {
      if (selectedPage !== 0) {
        dispatch(searchActions.setCurrentPage(selectedPage - 1));
        dispatch(fetchOffers(resultsPerPage - 1));
      }
    } else {
      const newId = offers[currentOfferIndex - 1].id;
      dispatch(fetchOffer(newId));
      dispatch(offersActions.setCurrentOfferId(newId));
    }
    setOfferNumber(offerNumber - 1);
  }, [currentOfferIndex, dispatch, offerNumber, offers, resultsPerPage, selectedPage]);

  const onShare = useCallback((offerId: string) => {
    navigator.clipboard.writeText(`${window.location.host}/${offerId ?? ''}`);
    toast.dark('le lien de partage a été copié dans votre presse-papier !', {
      position: toast.POSITION.BOTTOM_CENTER,
      toastId: `${window.location.host}/${offerId ?? ''}`,
      containerId: 'offer-detail',
    });
  }, []);

  const onOfferClick = useCallback(
    (offerId: string) => {
      dispatch(fetchOffer(offerId));
    },
    [dispatch]
  );

  const offersFromSameClient = useSelector(getSameClientOffers);
  const sameClientOffersFetchStatus = useSelector(getSameClientOffersFetchStatus);

  return (
    <OfferDetail
      offer={transformOfferDetail({
        offer: currentOffer,
        offersCount: offersCount,
        offerNumber: offerNumber,
      })}
      offerFetchStatus={aggregateFetchStatuses(offerFetchStatus, sameClientOffersFetchStatus)}
      onRetry={onRetry}
      goToNextOffer={goToNextOffer}
      goToPreviousOffer={goToPreviousOffer}
      canGoThroughOffers={canGoThroughOffers}
      onShare={onShare}
      offersFromSameClient={offersFromSameClient}
      onOfferClick={onOfferClick}
      isAuthenticated={isAuthenticated}
      {...otherProps}
    />
  );
};

export default EnhancedOfferDetail;
