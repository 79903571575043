import React from 'react';

import styles from './ToggleSwitch.module.scss';
import { Props } from './ToggleSwitch.types';

const ToggleSwitch = (props: Props) => {
  return (
    <>
      <input
        className={styles.reactSwitchCheckbox}
        id={`reactSwitchNew`}
        type="checkbox"
        checked={props.checked}
        onChange={event => {
          props.onCheckStatusChange(event.target.checked);
        }}
      />
      <label className={styles.reactSwitchLabel} htmlFor={`reactSwitchNew`}>
        <span className={styles.reactSwitchButton} />
      </label>
    </>
  );
};

export default ToggleSwitch;
