import {
  Button,
  FetchButton,
  Modal,
  PopupActions,
} from '@randstad-lean-mobile-factory/react-components-core';
import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { BDPDeleteWarning } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useFetchNafs } from 'src/Hooks/useFetchNafs';
import { CRITERIA_TYPE } from 'src/Redux/Search/Types';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/ReactQuery';

import { useExportSearch } from '../../../../Hooks/Export/useExportSearch';
import { getSearchState } from '../../../../Redux/Search/Selectors';
import {
  EnumExportOffersParamsAscending,
  EnumTypedLocationLocationType,
  ExportOffersParams,
  TypedLocation,
} from '../../../../Services/API';

import styles from './ExportSearchModal.module.scss';

const ExportSearchModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const ref = useRef<PopupActions>(null);

  const searchState = useSelector(getSearchState);
  const [qualifications, setQualifications] = useState<string[]>([]);
  const [locations, setLocations] = useState<TypedLocation[]>([]);
  const [keyword, setKeyword] = useState<string>('');

  useEffect(() => {
    setQualifications([]);
    setLocations([]);
    searchState.selectedCriterias.forEach(criteria => {
      const newLocation: TypedLocation = {
        label: criteria.criteria.value,
        locationType: criteria.criteria.subType ?? EnumTypedLocationLocationType.BE,
        postCode: criteria.criteria.key,
      };
      switch (criteria.type) {
        case CRITERIA_TYPE.CITIES:
          setLocations(prevState => [...prevState, newLocation]);
          break;
        case CRITERIA_TYPE.QUALIFICATIONS:
          setQualifications(prevState => [...prevState, criteria.criteria.value]);
          break;
        case CRITERIA_TYPE.KEYWORD:
          setKeyword(criteria.criteria.value);
          break;
      }
    });
  }, [searchState, searchState.selectedCriterias]);

  const { data: nafs } = useFetchNafs();
  const selectedNafs =
    searchState.businessGroup !== '' && nafs
      ? Object.values(nafs[searchState.businessGroup].groups)
          .map(naf => naf.cd_naf_international ?? [])
          .flat(1)
      : [];

  const exportParameters: ExportOffersParams = {
    ascending: searchState.orderBySettings.ascending
      ? EnumExportOffersParamsAscending.Asc
      : EnumExportOffersParamsAscending.Desc,
    businessActivities: selectedNafs.length === 0 ? undefined : selectedNafs,
    endDate: new Date(searchState.endDate),
    hideIntermediaryOffers: searchState.hideIntermediaryOffers,
    orderBy: searchState.orderBySettings.orderBy,
    pageIndex: searchState.currentPage,
    pageSize: searchState.resultsPerPage,
    qualifications: qualifications.length === 0 ? undefined : qualifications,
    keyWord: keyword,
    locations: locations.length === 0 ? undefined : locations,
    companies: undefined,
    startDate: new Date(searchState.startDate),
  };

  const exportMutationWithDetails = useExportSearch();
  const exportMutationWithoutDetails = useExportSearch();

  const exportWithFetchStatus = toFetchStatus(exportMutationWithDetails);
  const exportWithoutFetchStatus = toFetchStatus(exportMutationWithoutDetails);

  return (
    <Modal
      key={`Export_modal`}
      ref={ref}
      open={open}
      icon={<BDPDeleteWarning className={styles.icon} />}
      title="export des résultats de la recherche"
      onClose={() => {
        setOpen(false);
      }}
      footerActionsLeft={[
        <Button.Secondary key="close_export_modal_button" onClick={() => ref.current?.close()}>
          annuler
        </Button.Secondary>,
      ]}
      footerActionsRight={[
        <FetchButton
          title="exporter sans détail"
          key="validate_offer_comment_modal_button"
          disabled={exportWithoutFetchStatus !== FETCH_STATUS.FULFILLED}
          fetchStatus={exportWithoutFetchStatus}
          onClick={() => {
            exportMutationWithoutDetails.mutate({ body: exportParameters });
          }}
          onSuccess={() => {
            toast.success(
              "votre demande a bien été enregistrée, vous recevrez votre export par mail d'ici quelques minutes",
              { autoClose: 5000 }
            );
            setOpen(false);
          }}
        />,
        <FetchButton
          title="exporter avec détail"
          key="validate_offer_comment_modal_button"
          disabled={exportWithFetchStatus !== FETCH_STATUS.FULFILLED}
          fetchStatus={exportWithFetchStatus}
          onClick={() => {
            exportMutationWithDetails.mutate({ body: { ...exportParameters, withDetail: true } });
          }}
          onSuccess={() => {
            toast.success(
              "votre demande a bien été enregistrée, vous recevrez votre export par mail d'ici quelques minutes",
              { autoClose: 5000 }
            );
            setOpen(false);
          }}
        />,
      ]}
      nested
      lockScroll
    >
      <div>
        Vous êtes sur le point d'exporter les données que vous avez sélectionnées. Ce fichier vous
        sera envoyé sur votre boîte email. L'export des offres avec détails peut prendre jusqu'à 15
        minutes.
      </div>
    </Modal>
  );
};

export default ExportSearchModal;
