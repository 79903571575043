import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { SavedSearch, SavedSearchCreateParams, SavedSearchService } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useUpdateSearch = (
  extraOptions?: UseMutationOptions<
    SavedSearch,
    unknown,
    { body: SavedSearchCreateParams; id: number }
  >
) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MutationKeys.updateSavedSearch],
    async ({ body, id }: { body: SavedSearchCreateParams; id: number }) => {
      const updatedSearch = await SavedSearchService.savedSearchControllerUpdateSearch({
        id,
        body,
      });

      return updatedSearch;
    },
    {
      ...extraOptions,
      onSuccess: () => queryClient.invalidateQueries([QueryKeys.fetchConsultantFavorites]),
    }
  );
};
