import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import RootReducer from './RootReducer';

const store = configureStore({
  reducer: RootReducer,
  middleware: getDefaultMiddleware({}),
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./RootReducer', () => {
    const newRootReducer = RootReducer;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;

export default store;
