/** Generate by swagger-axios-codegen */
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { Expose, Transform, Type, plainToClass } from 'class-transformer';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(
  configs: IRequestConfig,
  resolve: (p: any) => void,
  reject: (p: any) => void
): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(
  method: string,
  contentType: string,
  url: string,
  options: any
): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType,
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class ConsultantService {
  /**
   *
   */
  static consultantControllerSaveSearch(
    params: {
      /** requestBody */
      body?: SavedSearchCreateParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SavedSearch> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/consultant/savedSearch';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SavedSearch, response)), reject);
    });
  }
  /**
   *
   */
  static consultantControllerGetConsultantFavorites(
    options: IRequestOptions = {}
  ): Promise<ConsultantFavorites> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/consultant/favorites';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(ConsultantFavorites, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static consultantControllerSetFavoriteSearch(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ConsultantData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/consultant/setFavoriteSearch';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(ConsultantData, response)), reject);
    });
  }
  /**
   *
   */
  static consultantControllerGetConsultantFavorite(
    options: IRequestOptions = {}
  ): Promise<ConsultantFavorite> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/consultant/favorite';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(ConsultantFavorite, response)),
        reject
      );
    });
  }
}

export class OffersService {
  /**
   *
   */
  static offersControllerGetOffers(
    params: {
      /** requestBody */
      body?: GetOffersBodyParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offers';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static offersControllerGetUserOfferHistory(
    options: IRequestOptions = {}
  ): Promise<UserOfferHistory> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offers/userOfferHistory';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(UserOfferHistory, response)), reject);
    });
  }
  /**
   *
   */
  static offersControllerGetOffer(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offers/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static offersControllerMarkOfferAsRead(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offers/{id}/markOfferAsRead';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static offersControllerCommentOffer(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateOfferCommentBodyParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offers/{id}/updateOfferComment';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static offersControllerOfferComments(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offers/{id}/comments';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class QualificationsService {
  /**
   *
   */
  static qualificationsControllerGetQualifications(
    params: {
      /**  */
      prefix: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Suggestion[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/qualifications';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { prefix: params['prefix'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Suggestion, response)), reject);
    });
  }
}

export class LocationsService {
  /**
   *
   */
  static locationsControllerGetLocations(
    params: {
      /**  */
      prefix: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TypedLocation[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/locations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { prefix: params['prefix'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(TypedLocation, response)), reject);
    });
  }
}

export class CompaniesService {
  /**
   *
   */
  static companiesControllerGetCompanies(
    params: {
      /**  */
      prefix: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Suggestion[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { prefix: params['prefix'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Suggestion, response)), reject);
    });
  }
}

export class ExportService {
  /**
   *
   */
  static exportControllerInitializeExportOffers(
    params: {
      /** requestBody */
      body?: ExportOffersParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/export/offers';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SavedSearchService {
  /**
   *
   */
  static savedSearchControllerUpdateSearch(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: SavedSearchCreateParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SavedSearch> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/savedSearch/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SavedSearch, response)), reject);
    });
  }
  /**
   *
   */
  static savedSearchControllerDeleteSearch(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/savedSearch/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TypedLocation {
  /**  */
  'locationType': EnumTypedLocationLocationType;

  /**  */
  'postCode': string;

  /**  */
  'label': string;

  constructor(data: undefined | any = {}) {
    this['locationType'] = data['locationType'];
    this['postCode'] = data['postCode'];
    this['label'] = data['label'];
  }
}

export class SavedSearchCreateParams {
  /**  */
  'businessActivity'?: string;

  /**  */
  'qualifications': string[];

  /**  */
  'keywords': string[];

  /**  */
  'companies': string[];

  /**  */
  'locations': TypedLocation[];

  /**  */
  'name': string;

  constructor(data: undefined | any = {}) {
    this['businessActivity'] = data['businessActivity'];
    this['qualifications'] = data['qualifications'];
    this['keywords'] = data['keywords'];
    this['companies'] = data['companies'];
    this['locations'] = data['locations'];
    this['name'] = data['name'];
  }
}

export class SavedSearch {
  /**  */
  'id': number;

  /**  */
  'consultant'?: ConsultantData;

  /**  */
  'locations'?: TypedLocation[];

  /**  */
  'businessActivity'?: string;

  /**  */
  'companies'?: string[];

  /**  */
  'qualifications'?: string[];

  /**  */
  'keywords'?: string[];

  /**  */
  'name': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['consultant'] = data['consultant'];
    this['locations'] = data['locations'];
    this['businessActivity'] = data['businessActivity'];
    this['companies'] = data['companies'];
    this['qualifications'] = data['qualifications'];
    this['keywords'] = data['keywords'];
    this['name'] = data['name'];
  }
}

export class ConsultantData {
  /**  */
  'consultantId': string;

  /**  */
  'savedSearches'?: SavedSearch[];

  /**  */
  'favoriteSearch'?: CombinedFavoriteSearchTypes;

  constructor(data: undefined | any = {}) {
    this['consultantId'] = data['consultantId'];
    this['savedSearches'] = data['savedSearches'];
    this['favoriteSearch'] = data['favoriteSearch'];
  }
}

export class Suggestion {
  /**  */
  'key': string;

  /**  */
  'value': string;

  /**  */
  'count'?: number;

  constructor(data: undefined | any = {}) {
    this['key'] = data['key'];
    this['value'] = data['value'];
    this['count'] = data['count'];
  }
}

export class SearchWithLabel {
  /**  */
  'qualifications'?: Suggestion[];

  /**  */
  'id': number;

  /**  */
  'consultant'?: ConsultantData;

  /**  */
  'locations'?: TypedLocation[];

  /**  */
  'businessActivity'?: string;

  /**  */
  'companies'?: string[];

  /**  */
  'keywords'?: string[];

  /**  */
  'name': string;

  constructor(data: undefined | any = {}) {
    this['qualifications'] = data['qualifications'];
    this['id'] = data['id'];
    this['consultant'] = data['consultant'];
    this['locations'] = data['locations'];
    this['businessActivity'] = data['businessActivity'];
    this['companies'] = data['companies'];
    this['keywords'] = data['keywords'];
    this['name'] = data['name'];
  }
}

export class ConsultantFavorites {
  /**  */
  'consultantId': string;

  /**  */
  'savedSearches'?: SearchWithLabel[];

  constructor(data: undefined | any = {}) {
    this['consultantId'] = data['consultantId'];
    this['savedSearches'] = data['savedSearches'];
  }
}

export class ConsultantFavorite {
  /**  */
  'consultantId': string;

  /**  */
  'favoriteSearch'?: CombinedFavoriteSearchTypes;

  constructor(data: undefined | any = {}) {
    this['consultantId'] = data['consultantId'];
    this['favoriteSearch'] = data['favoriteSearch'];
  }
}

export class GetOffersBodyParams {
  /**  */
  @Expose()
  @Type(() => Date)
  'startDate': Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate': Date;

  /**  */
  'keyWord'?: string;

  /**  */
  'pageIndex': number;

  /**  */
  'pageSize': number;

  /**  */
  'locations'?: TypedLocation[];

  /**  */
  'qualifications'?: string[];

  /**  */
  'companies'?: string[];

  /**  */
  'businessActivities'?: string[];

  /**  */
  'ascending': EnumGetOffersBodyParamsAscending;

  /**  */
  'orderBy': string;

  /**  */
  'hideIntermediaryOffers'?: boolean;

  constructor(data: undefined | any = {}) {
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['keyWord'] = data['keyWord'];
    this['pageIndex'] = data['pageIndex'];
    this['pageSize'] = data['pageSize'];
    this['locations'] = data['locations'];
    this['qualifications'] = data['qualifications'];
    this['companies'] = data['companies'];
    this['businessActivities'] = data['businessActivities'];
    this['ascending'] = data['ascending'];
    this['orderBy'] = data['orderBy'];
    this['hideIntermediaryOffers'] = data['hideIntermediaryOffers'];
  }
}

export class UserOfferHistory {
  /**  */
  'offerIds': string[];

  constructor(data: undefined | any = {}) {
    this['offerIds'] = data['offerIds'];
  }
}

export class UpdateOfferCommentBodyParams {
  /**  */
  'comments': string;

  /**  */
  'number': number;

  /**  */
  'userName': string;

  constructor(data: undefined | any = {}) {
    this['comments'] = data['comments'];
    this['number'] = data['number'];
    this['userName'] = data['userName'];
  }
}

export class ExportOffersParams {
  /**  */
  @Expose()
  @Type(() => Date)
  'startDate': Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate': Date;

  /**  */
  'keyWord'?: string;

  /**  */
  'pageIndex': number;

  /**  */
  'pageSize': number;

  /**  */
  'locations'?: TypedLocation[];

  /**  */
  'qualifications'?: string[];

  /**  */
  'companies'?: string[];

  /**  */
  'businessActivities'?: string[];

  /**  */
  'ascending': EnumExportOffersParamsAscending;

  /**  */
  'orderBy': string;

  /**  */
  'hideIntermediaryOffers'?: boolean;

  /**  */
  'withDetail'?: boolean;

  constructor(data: undefined | any = {}) {
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['keyWord'] = data['keyWord'];
    this['pageIndex'] = data['pageIndex'];
    this['pageSize'] = data['pageSize'];
    this['locations'] = data['locations'];
    this['qualifications'] = data['qualifications'];
    this['companies'] = data['companies'];
    this['businessActivities'] = data['businessActivities'];
    this['ascending'] = data['ascending'];
    this['orderBy'] = data['orderBy'];
    this['hideIntermediaryOffers'] = data['hideIntermediaryOffers'];
    this['withDetail'] = data['withDetail'];
  }
}
export enum EnumTypedLocationLocationType {
  'COM' = 'COM',
  'REG' = 'REG',
  'GREG' = 'GREG',
  'DEP' = 'DEP',
  'BE' = 'BE',
}
export type CombinedFavoriteSearchTypes = SearchWithLabel;
export enum EnumGetOffersBodyParamsAscending {
  'asc' = 'asc',
  'Asc' = 'Asc',
  'desc' = 'desc',
  'Desc' = 'Desc',
}
export enum EnumExportOffersParamsAscending {
  'asc' = 'asc',
  'Asc' = 'Asc',
  'desc' = 'desc',
  'Desc' = 'Desc',
}
