import React from 'react';
import { Provider } from 'react-redux';

import store from 'src/Redux/store';

const ReduxProvider = ({ children }: { children: React.ReactElement }) => (
  <Provider store={store}>{children}</Provider>
);

export default ReduxProvider;
