import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useAuth from 'src/Hooks/Authentication/useAuth';
import { getNafFetchStatus } from 'src/Redux/Nafs/Selector';
import { fetchNafs } from 'src/Redux/Nafs/Thunks';
import { FETCH_STATUS } from 'src/Redux/Types';
import history from 'src/Utils/history';

import Callback from './Callback.component';

const EnhancedCallback = () => {
  const auth = useAuth();
  const fetchStatus = useSelector(getNafFetchStatus);
  const dispatch = useDispatch();
  // we need this inner state because fetchStatus is fulfilled when the component mounts
  const [isFetchingNafs, setIsFetchingNafs] = useState(false);
  useEffect(() => {
    const isAuthenticated = auth?.isAuthenticated ?? false;
    if (!isAuthenticated) {
      return;
    } else if (isAuthenticated && !isFetchingNafs) {
      dispatch(fetchNafs());
      setIsFetchingNafs(true);
    } else if (isAuthenticated && fetchStatus === FETCH_STATUS.FULFILLED) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, fetchStatus, fetchNafs, auth]);

  return <Callback fetchStatus={fetchStatus} />;
};

export default EnhancedCallback;
