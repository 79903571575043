import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { SavedSearchService } from 'src/Services/API';

import { useFetchConsultantFavorites } from '../Consultant/useFetchConsultantFavorites';
import { MutationKeys, QueryKeys } from '../types';

export const useDeleteSearch = (
  extraOptions?: UseMutationOptions<void, unknown, { id: number }>
) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MutationKeys.deleteSavedSearch],
    async ({ id }: { id: number }) => {
      await SavedSearchService.savedSearchControllerDeleteSearch({
        id,
      });
    },
    {
      ...extraOptions,
      onSuccess: async (_, { id }) =>
        queryClient.setQueryData(
          [QueryKeys.fetchConsultantFavorites],
          (oldData: ReturnType<typeof useFetchConsultantFavorites>['data']) => {
            return {
              consultantId: oldData?.consultantId ?? '',
              savedSearches: oldData?.savedSearches?.filter(search => search.id !== id),
            };
          }
        ),
    }
  );
};
