import React from 'react';

import styles from './IconButton.module.scss';
import { Props } from './IconButton.types';

const IconButton = (props: Props) => (
  <button className={styles.container} onClick={props.onClick}>
    <img src={props.icon} alt="icon button" />
  </button>
);

export default IconButton;
