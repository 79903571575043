import {
  Button,
  ParametersPopup,
  RandstadAppsPopup,
  SegmentedMenu,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  Fav,
  Find,
  Gear,
  Menu,
  PigeProd,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { useHistory, useLocation } from 'react-router-dom';

import { arrowRight } from 'src/Assets';
import IconButton from 'src/Components/Buttons/IconButton/IconButton.component';
import CriteriaSearchInput from 'src/Components/CriteriaSearchInput';
import LocationSearchInput from 'src/Components/LocationSearchInput';
import NafsButton from 'src/Containers/Nafs/NafsButton';
import useAuth from 'src/Hooks/Authentication/useAuth';
import { useIsProductionEnvironment } from 'src/Hooks/Environment/useIsProductionEnvironment';

import styles from './TopBar.module.scss';
import { Props } from './TopBar.types';

enum TOP_BAR_URL {
  FAVORITES = '/favorites',
}

const getActiveSection = (url: string) => {
  if (url.includes(TOP_BAR_URL.FAVORITES)) {
    return 'favorites';
  } else {
    return 'homePage';
  }
};

const getTopBarNavigation = (tab: string) => {
  return {
    homePage: {
      name: "offres d'emploi",
      path: '',
      icon: (
        <Find className={classnames(styles.icon, { [styles.selectedIcon]: tab === 'homePage' })} />
      ),
    },
    favorites: {
      name: 'enregistré',
      path: '/favorites',
      icon: (
        <Fav
          className={classnames(styles.heartIcon, { [styles.selectedIcon]: tab === 'favorites' })}
        />
      ),
    },
  };
};

const TopBar = (props: Props) => {
  const auth = useAuth();
  const { isProd } = useIsProductionEnvironment();
  const location = useLocation();
  const history = useHistory();
  const activeSection = getActiveSection(location.pathname);
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div className={styles.fullContainer}>
      <div className={styles.topContainer}>
        <PigeProd className={styles.appLogo} />
        <div className={styles.separator} />
        <div className={styles.title}>{'la pige'}</div>

        <div className={styles.topButtons}>
          <SegmentedMenu
            selected={activeSection}
            controlItemClassName={styles.segmentedMenuText}
            bottomBarClassName={styles.bottomBar}
            controls={Object.entries(getTopBarNavigation(activeSection))}
            keyValueExtractor={([section, { name, icon }]) => ({
              key: section,
              value: name,
              leftIcon: icon,
            })}
            onSelectionChange={([_, { path }]) => {
              history.push(path);
            }}
          />
          <RandstadAppsPopup
            isProd={isProd}
            onOpen={() => setIsOpened(true)}
            onClose={() => setIsOpened(false)}
            onItemClick={name => {
              ReactGA.event(`Navigation vers une autre application`, {
                name,
              });
            }}
            trigger={
              <div>
                <Button.Tertiary
                  className={classnames(styles.button, styles.whiteColor, {
                    [styles.openButton]: isOpened,
                  })}
                >
                  <Menu className={classnames(styles.buttonIcon)} />
                </Button.Tertiary>
              </div>
            }
          />

          <ParametersPopup
            trigger={
              <div>
                <Button.Tertiary className={classnames(styles.gearButton, styles.whiteColor)}>
                  <Gear />
                </Button.Tertiary>
              </div>
            }
            position="top right"
            onLogout={() =>
              auth.logout({ returnTo: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}/login` })
            }
            className={styles.whiteColor}
            firstName={auth.user?.given_name}
            lastName={auth.user?.family_name}
            version={process.env.REACT_APP_VERSION && `${process.env.REACT_APP_VERSION}`}
          />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.horizontalSeparator} />
        <div className={styles.bottomContainer}>
          <div className={styles.citySearchContainer}>
            <div className={styles.searchTitle}>lieu(x)</div>
            <LocationSearchInput />
          </div>
          <div className={styles.clientSearchContainer}>
            <div className={styles.searchTitle}>critères de recherche</div>
            <CriteriaSearchInput />
          </div>
          <div className={styles.nafSearchContainer}>
            <div className={styles.searchTitle}>secteur d'activité</div>
            <NafsButton />
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.searchTitle} />
            <IconButton
              onClick={() => {
                props.onSearch();
                history.push('/');
              }}
              icon={arrowRight}
            />
          </div>
        </div>
        <div className={styles.favContainer}>
          <div
            className={styles.triggerContainer}
            onClick={() => {
              history.push({
                pathname: '/editSearch',
                state: { modalParameters: { searchToModify: undefined }, background: location },
              });
            }}
          >
            <Fav />
            <p className={styles.triggerText}>sauvegarder la recherche</p>
          </div>
          <div className={styles.verticalSeparator} />
          <p onClick={() => history.push('/favorites')} className={styles.favText}>
            voir les recherches enregistrées
          </p>
        </div>
      </div>
    </div>
  );
};
export default TopBar;
