import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import { Sort, Tick } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useState } from 'react';

import { ORDER_BY_ELEM } from 'src/Redux/Search/Types';

import ToggleSwitch from '../ToggleSwitch';

import styles from './OrderByMenu.module.scss';
import { Props } from './OrderByMenu.types';

const OrderByMenu = (props: Props) => {
  const orderByItems = Object.values(ORDER_BY_ELEM);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <PopupMenu
      position="bottom right"
      onOpen={() => setIsOpen(true)}
      onClose={() => {
        setIsOpen(false);
        props.onClose();
      }}
      width={280}
      trigger={
        <Button.Tertiary className={classnames(styles.trigger, { [styles.activeTrigger]: isOpen })}>
          <Sort className={styles.sortIcon} />
        </Button.Tertiary>
      }
    >
      <>
        <div className={styles.switchRow}>
          <span className={styles.switchText}>croissant</span>
          <ToggleSwitch
            onCheckStatusChange={(checked: boolean) =>
              props.updateOrderBySettings({
                ascending: !checked,
                orderBy: props.orderBySettings.orderBy,
              })
            }
            checked={!props.orderBySettings.ascending}
          />
          <span className={styles.switchText}>décroissant</span>
        </div>
        {orderByItems.map((item, index) => {
          const isItemSelected = item === props.orderBySettings.orderBy;
          return (
            <PopupMenu.Item
              key={index}
              rightIcon={isItemSelected && <Tick />}
              text={item}
              onClick={() => {
                props.updateOrderBySettings({
                  ascending: props.orderBySettings.ascending,
                  orderBy: item,
                });
              }}
            />
          );
        })}
      </>
    </PopupMenu>
  );
};

export default OrderByMenu;
