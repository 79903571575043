import React from 'react';

import styles from './Anchor.module.scss';
import { Props } from './Anchor.types';

const Anchor = ({ children, placeholder, ...props }: Props) =>
  props.href ? (
    <a className={styles.container} {...props}>
      {children}
    </a>
  ) : (
    <p className={styles.emptyMessage}>{placeholder}</p>
  );

export default Anchor;
