import React from 'react';

import styles from './LightTitledContent.module.scss';
import { Props } from './LightTitledContent.types';

const LightTitledContent = ({ title, textAlign, children, containerStyles }: Props) => (
  <div className={containerStyles}>
    <p className={styles.title} style={{ textAlign: textAlign || 'left' }}>
      {title}
    </p>
    {children}
  </div>
);

export default LightTitledContent;
