import { Checkbox } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TextButton } from 'src/Components/Buttons';
import DateRangePickerButton from 'src/Containers/DateRange/DateRangePickerButton';
import { fetchOffers } from 'src/Redux/Offers/Thunks';
import { getHideIntermediaryOffers } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';

import styles from './SideBar.module.scss';

const SideBar = () => {
  const dispatch = useDispatch();
  const hideIntermediaryOffers = useSelector(getHideIntermediaryOffers);
  const setHideIntermediaryOffers = useCallback(
    (hideIntermediaryOffers: boolean) => {
      dispatch(searchActions.setHideIntermediaryOffers(hideIntermediaryOffers));
      dispatch(fetchOffers());
    },
    [dispatch]
  );
  const reset = useCallback(() => {
    dispatch(searchActions.resetSideCriterias());
    dispatch(fetchOffers());
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.titleBar}>
        <div className={styles.title}>filtres</div>
        <TextButton title={'réinitialiser'} onClick={() => reset()} />
      </div>
      <div className={styles.separator} />
      <div className={styles.titleBar}>
        <div className={styles.subtitle}>date de parution</div>
      </div>
      <div className={styles.dateRangePicker}>
        <DateRangePickerButton />
      </div>
      <div className={styles.separator} />
      <div className={styles.titleBar}>
        <div className={styles.subtitle}>offres</div>
      </div>
      <Checkbox
        label="masquer les annonces des intermédiaires"
        onChange={value => setHideIntermediaryOffers(value.target.checked)}
        checked={hideIntermediaryOffers}
      />
    </div>
  );
};

export default SideBar;
