import { Offer, OfferDetail } from 'src/Redux/Offers/Types';
import { CRITERIA_TYPE } from 'src/Redux/Search/Types';
import { FETCH_STATUS } from 'src/Redux/Types';

export const transformOfferDetail = ({
  offer,
  offersCount,
  offerNumber,
}: {
  offer?: Offer;
  offersCount: number;
  offerNumber: number;
}): OfferDetail => {
  return {
    id: offer?.id,
    sector: offer?.LB_OCCUPATION,
    contractType: offer?.LB_CONTRACT_TYPE,
    siret: offer?.CD_BUSINESS_NATIONAL_ID,
    isOsmose: offer?.FG_IN_NATIONAL_OIS === '1',
    offerTitle: offer?.LB_OCCUPATION,
    webSite: offer?.LB_URL,
    phoneNumber: offer?.NB_BUSINESS_PHONE,
    mail: offer?.LB_BUSINESS_EMAIL,
    city: offer?.LB_CITY_FACET,
    postCode: offer?.CD_POSTCODE,
    publishDate: offer?.DT_POSTING_DATE,
    expireDate: offer?.DT_EXPIRATION_DATE,
    company: offer?.LB_BUSINESS_NAME,
    description: offer?.TEXT,
    offersCount,
    offerNumber,
  };
};

export const aggregateFetchStatuses = (...fetchStatuses: FETCH_STATUS[]): FETCH_STATUS => {
  if (fetchStatuses.some(x => x === FETCH_STATUS.REJECTED)) {
    return FETCH_STATUS.REJECTED;
  }

  if (fetchStatuses.some(x => x === FETCH_STATUS.PENDING)) {
    return FETCH_STATUS.PENDING;
  }

  if (fetchStatuses.every(x => x === FETCH_STATUS.FULFILLED)) {
    return FETCH_STATUS.FULFILLED;
  }

  return FETCH_STATUS.REJECTED;
};

export const getTypeLabel = (option: {
  key: string;
  value: string;
  type: CRITERIA_TYPE;
}): string => {
  switch (option.type) {
    case CRITERIA_TYPE.COMPANIES:
      return 'nom de société';
    case CRITERIA_TYPE.QUALIFICATIONS:
      return 'métiers';
    case CRITERIA_TYPE.KEYWORD:
      return 'mot-clés';
    default:
      throw new Error('Invalid option type');
  }
};
