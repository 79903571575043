import React from 'react';
import { useHistory } from 'react-router-dom';

import { glasses, illusEscalierRed } from 'src/Assets';
import OrderByMenu from 'src/Components/OrderByMenu';
import SearchResult from 'src/Components/SearchResult';
import PageNavigator from 'src/Containers/PageNavigator';
import { useFetchSeenOffers } from 'src/Hooks/useFetchSeenOffers';
import { FETCH_STATUS } from 'src/Redux/Types';
import moment from 'src/Utils/moment';

import styles from './Results.module.scss';
import { Props } from './Results.types';

const Results = (props: Props) => {
  const fetchSeenOffers = useFetchSeenOffers();
  const history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.topRow}>
        <div className={styles.title}>résultats</div>
        <OrderByMenu
          orderBySettings={props.orderBySettings}
          updateOrderBySettings={props.updateOrderBySettings}
          onClose={props.onSearch}
        />
      </div>
      <div className={styles.separator} />
      <div className={styles.resultList}>
        {props.fetchStatus === FETCH_STATUS.FULFILLED && props.results.length !== 0 && (
          <>
            {props.results.map((result, index) => {
              let subtitle = result.LB_BUSINESS_NAME;
              subtitle = [subtitle, result.CD_POSTCODE?.substring(0, 2)]
                .filter(item => !!item)
                .join(' - ');
              subtitle = [subtitle, result.LB_SUB_REGION].filter(item => !!item).join(' | ');

              const wasSeen = Object.values(fetchSeenOffers.data?.offerIds ?? {}).includes(
                result.id
              );

              return (
                <SearchResult
                  key={result.id}
                  onClick={() => {
                    props.fetchOfferDetail(result);
                    history.push(`/offer/${result.id}`);
                  }}
                  title={result.LB_OCCUPATION ?? ''}
                  subtitle={subtitle ?? ''}
                  isFirst={index === 0}
                  rightFields={[
                    result.LB_CONTRACT_TYPE ?? '',
                    'Posté ' +
                      moment(result.DT_POSTING_DATE).calendar({
                        sameDay: "[aujourd'hui]",
                        lastDay: '[hier]',
                        lastWeek: 'dddd [dernier]',
                        sameElse: '[le] L',
                      }),
                    'Osmose : ' + (result.FG_IN_NATIONAL_OIS === '1' ? 'oui' : 'non'),
                  ]}
                  wasSeen={wasSeen}
                  offerId={result.id}
                />
              );
            })}
          </>
        )}
        {props.fetchStatus === FETCH_STATUS.FULFILLED && props.results.length === 0 && (
          <div className={styles.noResult}>
            <img src={glasses} alt="glasses" className={styles.noResultImage} />
            <div className={styles.noResultText}>Pas de résultats</div>
          </div>
        )}
        {props.fetchStatus === FETCH_STATUS.PENDING &&
          [...Array(props.resultsPerPage)].map((_, index) => (
            <SearchResult.Loading key={`key ${index.toString()}`} uniqueKey={index.toString()} />
          ))}
        {props.fetchStatus === FETCH_STATUS.REJECTED && (
          <div className={styles.noResult}>
            <img src={illusEscalierRed} alt="illusEscalierRed" className={styles.noResultImage} />
            <div className={styles.noResultText}>Une erreur est survenue</div>
          </div>
        )}
      </div>
      {props.results.length !== 0 && (
        <div className={styles.pageNavigator}>
          <PageNavigator />
        </div>
      )}
    </div>
  );
};

export default Results;
