import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getNafs } from 'src/Redux/Nafs/Selector';
import { getBusinessGroup } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';

import NafsButton from './NafsButton.component';

const EnhancedNafsButton = () => {
  const dispatch = useDispatch();
  const currentBusinessGroup = useSelector(getBusinessGroup);
  const nafs = useSelector(getNafs);
  const onNafCategoryUpdate = useCallback(
    (nafGroup: string) => {
      dispatch(searchActions.setBusinessGroup(nafGroup));
    },
    [dispatch]
  );

  const title = currentBusinessGroup !== '' ? nafs[currentBusinessGroup].label : 'N21 (par défaut)';
  return <NafsButton title={title} nafs={nafs} onBusinessCategoryUpdate={onNafCategoryUpdate} />;
};

export default EnhancedNafsButton;
