import { useMutation, UseMutationOptions } from 'react-query';

import { LocationsService, TypedLocation } from 'src/Services/API';

import { MutationKeys } from './types';

export const useFetchLocationSuggestions = (
  extraOptions?: UseMutationOptions<TypedLocation[], unknown, string>
) => {
  return useMutation(
    [MutationKeys.locationSuggestions],
    async (prefix: string) => {
      const suggestions = await LocationsService.locationsControllerGetLocations({
        prefix,
      });

      const groupOrder = {
        COM: 4,
        BE: 3,
        DEP: 2,
        REG: 1,
        GREG: 0,
      };

      return suggestions.sort((a, b) => groupOrder[a.locationType] - groupOrder[b.locationType]);
    },
    { ...extraOptions }
  );
};
