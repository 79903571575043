import { UseQueryOptions, useQuery } from 'react-query';

import { ConsultantFavorite, ConsultantService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchConsultantFavorite = (
  extraOptions?: UseQueryOptions<ConsultantFavorite, unknown, ConsultantFavorite, QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchConsultantFavorite],
    async () => {
      const consultantData = await ConsultantService.consultantControllerGetConsultantFavorite();
      return consultantData;
    },
    { ...extraOptions }
  );
};
