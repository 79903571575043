import { createSlice } from '@reduxjs/toolkit';

import { FETCH_STATUS } from 'src/Redux/Types';

import { fetchNafs } from './Thunks';
import { NafsState } from './Types';

const initialState: NafsState = {
  nafs: {},
  nafsFetchStatus: FETCH_STATUS.FULFILLED,
};

export const { reducer: nafsReducer, actions: nafsActions } = createSlice({
  name: 'nafs',
  initialState,
  reducers: {
    reset: state => {
      state.nafs = {};
      state.nafsFetchStatus = FETCH_STATUS.FULFILLED;
    },
  },
  extraReducers: builder => {
    // Search missions
    builder.addCase(fetchNafs.pending, state => {
      state.nafsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(fetchNafs.fulfilled, (state, action) => {
      state.nafs = action.payload;
      state.nafsFetchStatus = FETCH_STATUS.FULFILLED;
    });
    builder.addCase(fetchNafs.rejected, state => {
      state.nafsFetchStatus = FETCH_STATUS.REJECTED;
    });
  },
});
