import { DropDown, Popup } from '@randstad-lean-mobile-factory/react-components-core';
import { PopupActions } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useRef } from 'react';

import { presetTimeRanges, PresetTimeRangesKeys } from 'src/Services/Search';

import DateRangePopOver from '../DateRangePopOver';

import styles from './DateRangePickerButton.module.scss';
import { Props } from './DateRangePickerButton.types';
const CUSTOM_TIME_RANGE_KEY = 'personnaliser';

const DateRangePickerButton = (props: Props) => {
  const dateRangePopupRef = useRef<PopupActions>(null);
  return (
    <div>
      <DropDown
        className={styles.container}
        placeholder={
          props.startDate.format('DD/MM/YYYY') + ' - ' + props.endDate.format('DD/MM/YYYY')
        }
        items={[...Object.keys(presetTimeRanges), CUSTOM_TIME_RANGE_KEY]}
        onSelectItem={(item: string) => {
          if (item === CUSTOM_TIME_RANGE_KEY) {
            dateRangePopupRef.current?.open();
          } else {
            props.updateSelectedPredefinedTime(
              (item as PresetTimeRangesKeys) ?? Object.keys(presetTimeRanges)[0]
            );
          }
        }}
        keyValueExtractor={(item: string) => ({ key: item, value: item })}
      />

      <Popup ref={dateRangePopupRef}>
        {(close: () => void) => (
          <DateRangePopOver
            onClose={() => close()}
            onCancel={() => {
              close();
            }}
          />
        )}
      </Popup>
    </div>
  );
};

export default DateRangePickerButton;
