import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, useLocation, useRouteMatch, Router, useHistory } from 'react-router-dom';

import PrivateRoute from 'src/Components/PrivateRoute';
import useAuth from 'src/Hooks/Authentication/useAuth';
import { useFetchConsultantFavorite } from 'src/Hooks/Consultant/useFetchConsultantFavorite';
import { fetchOffers } from 'src/Redux/Offers/Thunks';
import { searchActions } from 'src/Redux/Search/Slice';
import { CRITERIA_TYPE } from 'src/Redux/Search/Types';
import { toFetchStatus } from 'src/Services/ReactQuery';

import FavoritesPage from '../FavoritesPage';

import styles from './MainPage.module.scss';
import { LocationState, Props } from './MainPage.types';
import MainPageContent from './MainPageContent';
import TopBar from './TopBar';

function MainPage(props: Props) {
  useAuth();
  useEffect(() => {
    props.initialAPIFetch();
  });
  const match = useRouteMatch();
  const location = useLocation<LocationState>();
  const locationState = location.state;
  const background = locationState?.background;
  const history = useHistory();
  const dispatch = useDispatch();
  const { data, isError, isLoading, isSuccess } = useFetchConsultantFavorite();
  const favoriteFetchStatus = toFetchStatus({ isError, isLoading, isSuccess });
  const [isFirstOpening, setFirstOpening] = useState(true);

  useEffect(() => {
    if (!isFirstOpening) {
      dispatch(searchActions.resetSelectedCriterias());
      dispatch(fetchOffers());
    }
    if (isFirstOpening && favoriteFetchStatus && data?.favoriteSearch) {
      setFirstOpening(false);
      dispatch(searchActions.resetSelectedCriterias());
      if (
        data.favoriteSearch?.locations !== undefined &&
        data.favoriteSearch.locations.length > 0
      ) {
        dispatch(
          searchActions.updateSelectedCriterias({
            type: CRITERIA_TYPE.CITIES,
            criterias: data.favoriteSearch.locations.map(location => {
              return {
                key: location.postCode,
                value: location.label,
                subType: location.locationType,
              };
            }),
          })
        );
      }
      if (
        data.favoriteSearch?.qualifications !== undefined &&
        data.favoriteSearch.qualifications.length > 0
      ) {
        dispatch(
          searchActions.updateSelectedCriterias({
            type: CRITERIA_TYPE.QUALIFICATIONS,
            criterias: data.favoriteSearch.qualifications,
          })
        );
      }
      if (
        data.favoriteSearch?.companies !== undefined &&
        data.favoriteSearch.companies.length > 0
      ) {
        dispatch(
          searchActions.updateSelectedCriterias({
            type: CRITERIA_TYPE.COMPANIES,
            criterias: data.favoriteSearch.companies.map(company => {
              return { key: company, value: company };
            }),
          })
        );
      }
      dispatch(searchActions.setCurrentPage(0));
      dispatch(fetchOffers());
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch, favoriteFetchStatus, history]);

  return (
    <div className={styles.container}>
      <TopBar />
      <Router history={history}>
        <Switch location={background || location}>
          <PrivateRoute exact path="/favorites" component={FavoritesPage} />
          <PrivateRoute path={match.path} component={MainPageContent} />
        </Switch>
      </Router>
    </div>
  );
}

export default MainPage;
