import { Button, DropDown } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getResultsPerPage } from 'src/Redux/Search/Selectors';

import ExportSearchModal from '../Modal/ExportSearchModal';
import Results from '../Results';
import SideBar from '../SideBar';

import styles from './MainPageContent.module.scss';
import { Props } from './MainPageContent.types';

const MainPageContent = (props: Props) => {
  const resultsPerPage = useSelector(getResultsPerPage);

  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className={styles.container}>
      <div className={styles.topRow}>
        <p className={styles.title}>{`${props.numberOfResults} offres`}</p>
        <div className={styles.offerPerPage}>
          <p className={styles.subTitle}>offres par page</p>
          <DropDown
            className={styles.dropDown}
            selectedItem={resultsPerPage.toString()}
            placeholder={resultsPerPage.toString()}
            items={['20', '50', '100', '200']}
            onSelectItem={(value: string) => {
              props.onResultPerPageUpdate(parseInt(value ?? '0'));
              props.onSearch();
            }}
            keyValueExtractor={(item: string) => ({ key: item, value: item })}
          />
          <Button.Primary
            text="exporter"
            onClick={() => {
              setOpen(true);
            }}
          />
          <ExportSearchModal open={open} setOpen={setOpen} />
        </div>
      </div>
      <div className={styles.subContainer}>
        <SideBar />
        <Results />
      </div>
    </div>
  );
};

export default MainPageContent;
