import React from 'react';

import styles from './Link.module.scss';
import { Props } from './Link.types';

const Link = (props: Props) => (
  <div onClick={props.onClick} className={styles.container}>
    {props.value}
  </div>
);

export default Link;
