import axios, { AxiosResponse } from 'axios';
import ReactGA from 'react-ga4';

import { Offer } from 'src/Redux/Offers/Types';
import { ORDER_BY_ELEM } from 'src/Redux/Search/Types';

import { TypedLocation } from '.';

interface GetOffersParams {
  startDate: Date;
  endDate: Date;
  keyword?: string;
  pageIndex: number;
  pageSize: number;
  businessActivities?: string[];
  locations?: TypedLocation[];
  qualifications?: string[];
  companies?: string[];
  ascending?: boolean;
  orderBy?: ORDER_BY_ELEM;
  hideIntermediaryOffers?: boolean;
}

export interface GetOffersReturn {
  msgError: string;
  nbElements: number;
  result: Offer[];
}

export interface GetOfferDetailReturn {
  msgError: string;
  result?: Offer;
}

export async function getOffers(params: GetOffersParams) {
  if (params.businessActivities === undefined || params.businessActivities.length === 0)
    delete params.businessActivities;
  if (params.locations === undefined || params.locations.length === 0) delete params.locations;
  if (params.qualifications === undefined || params.qualifications.length === 0)
    delete params.qualifications;
  if (params.companies === undefined || params.companies.length === 0) delete params.companies;

  const offers = await axios
    .post('/offers', params)
    .then((res: AxiosResponse<GetOffersReturn>) => res.data);
  if (offers.result) ReactGA.event(`Recherche d'annonces`);
  return offers;
}

export async function getOfferById(id: string) {
  return axios.get(`/offers/${id}`).then((res: AxiosResponse<GetOfferDetailReturn>) => res.data);
}
