import moment from 'moment';
import { Moment } from 'moment';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchOffers } from 'src/Redux/Offers/Thunks';
import { getEndDate, getStartDate } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';

import DateRangePopOver from './DateRangePopOver.component';
import { Props } from './DateRangePopOver.types';

const EnhancedDateRangePopOver = (
  otherProps: Omit<Props, 'onValidate' | 'startDate' | 'endDate'>
) => {
  const dispatch = useDispatch();

  const startDate = moment(useSelector(getStartDate));
  const endDate = moment(useSelector(getEndDate));

  const onDateRangeChange = useCallback(
    (dateRange: [Moment, Moment]) => {
      dispatch(searchActions.setStartDate(dateRange[0].toISOString()));
      dispatch(searchActions.setEndDate(dateRange[1].toISOString()));
      dispatch(searchActions.setCurrentPage(0));
      dispatch(fetchOffers());
    },
    [dispatch]
  );

  return (
    <DateRangePopOver
      onValidate={onDateRangeChange}
      startDate={startDate}
      endDate={endDate}
      {...otherProps}
    />
  );
};

export default EnhancedDateRangePopOver;
