import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { fetchNafs } from 'src/Redux/Nafs/Thunks';

import MainPage from './MainPage.component';

const EnhancedMainPage = () => {
  const dispatch = useDispatch();

  const initialAPIFetch = useCallback(() => {
    dispatch(fetchNafs());
  }, [dispatch]);

  return <MainPage initialAPIFetch={initialAPIFetch} />;
};

export default EnhancedMainPage;
