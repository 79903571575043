import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/Redux/RootReducer';

import { NafsState } from './Types';

const getNafsState = (state: RootState) => state.nafs;

export const getNafs = createSelector([getNafsState], (state: NafsState) => state.nafs);

export const getNafFetchStatus = createSelector(
  [getNafsState],
  (state: NafsState) => state.nafsFetchStatus
);
