import fr from 'date-fns/locale/fr';
import moment from 'moment';
import { Moment } from 'moment';
import React, { useState } from 'react';
import './DateRangePicker.scss';
import DatePicker, { registerLocale } from 'react-datepicker';

import { Props } from './DateRangePicker.types';

registerLocale('fr', fr);

const DateRangePicker = (props: Props) => {
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState<Moment | null>(props.endDate);

  const onChange = (dates: [Date, Date]) => {
    const [start, end] = dates.map(d => moment(d));
    setStartDate(start);
    setEndDate(end.isValid() ? end : null);
    props.onRangeChange([start, end]);
  };

  return (
    <DatePicker
      inline
      selectsRange
      locale="fr"
      monthsShown={2}
      calendarClassName="react-datepicker__custom-container"
      showPopperArrow={false}
      shouldCloseOnSelect={false}
      openToDate={startDate.toDate()}
      startDate={startDate.toDate()}
      endDate={endDate?.toDate()}
      onChange={onChange}
      showDisabledMonthNavigation
    />
  );
};

export default DateRangePicker;
