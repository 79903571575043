import { HorizontalCardWithTitle } from '@randstad-lean-mobile-factory/react-components-core';
import {
  FavoriteFill,
  FavoriteLine,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { glasses } from 'src/Assets';
import { useFetchConsultantFavorite } from 'src/Hooks/Consultant/useFetchConsultantFavorite';
import { useFetchConsultantFavorites } from 'src/Hooks/Consultant/useFetchConsultantFavorites';
import { useSetFavoriteSearch } from 'src/Hooks/Consultant/useSetFavoriteSearch';
import { QueryKeys } from 'src/Hooks/types';
import { getNafFetchStatus, getNafs } from 'src/Redux/Nafs/Selector';
import { fetchOffers } from 'src/Redux/Offers/Thunks';
import { searchActions } from 'src/Redux/Search/Slice';
import { CRITERIA_TYPE } from 'src/Redux/Search/Types';
import { FETCH_STATUS } from 'src/Redux/Types';
import { Suggestion, TypedLocation } from 'src/Services/API';
import { mergeFetchStatus, toFetchStatus } from 'src/Services/ReactQuery';

import FavoriteSearchActionButton from './FavoriteSearchActionButton/FavoriteSearchActionButton.component';
import styles from './FavoritesSearchesList.module.scss';
const FavoritesSearchesList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data, isError, isLoading, isSuccess } = useFetchConsultantFavorites();
  const favoriteSearch = useFetchConsultantFavorite();
  const nafs = useSelector(getNafs);
  const nafsFetchStatus = useSelector(getNafFetchStatus);
  const gloablFetchStatus = mergeFetchStatus(
    nafsFetchStatus,
    toFetchStatus({ isError, isLoading, isSuccess })
  );
  const queryClient = useQueryClient();
  const saveFavoriteSearch = useSetFavoriteSearch({
    onSuccess: data => {
      queryClient.invalidateQueries([QueryKeys.fetchConsultantFavorite]);
      queryClient.setQueryData(QueryKeys.fetchConsultantFavorite, () => {
        return data;
      });
    },
  });
  const removeFavoriteSearch = useSetFavoriteSearch({
    onSuccess: data => {
      queryClient.invalidateQueries([QueryKeys.fetchConsultantFavorite]);
      queryClient.setQueryData(QueryKeys.fetchConsultantFavorite, () => {
        return (data.favoriteSearch = undefined);
      });
    },
  });

  const onSearch = useCallback(
    (params: {
      locations?: TypedLocation[];
      qualifications?: Suggestion[];
      companies?: string[];
      keywords?: string[];
    }) => {
      dispatch(searchActions.resetSelectedCriterias());
      if (params.locations !== undefined && params.locations.length > 0) {
        dispatch(
          searchActions.updateSelectedCriterias({
            type: CRITERIA_TYPE.CITIES,
            criterias: params.locations.map(location => {
              return {
                key: location.postCode,
                value: location.label,
                subType: location.locationType,
              };
            }),
          })
        );
      }
      if (params.qualifications !== undefined && params.qualifications.length > 0) {
        dispatch(
          searchActions.updateSelectedCriterias({
            type: CRITERIA_TYPE.QUALIFICATIONS,
            criterias: params.qualifications,
          })
        );
      }
      if (params.companies !== undefined && params.companies.length > 0) {
        dispatch(
          searchActions.updateSelectedCriterias({
            type: CRITERIA_TYPE.COMPANIES,
            criterias: params.companies.map(company => {
              return { key: company, value: company };
            }),
          })
        );
      }
      if (params.keywords !== undefined && params.keywords.length > 0) {
        dispatch(
          searchActions.updateSelectedCriterias({
            type: CRITERIA_TYPE.COMPANIES,
            criterias: params.keywords.map(keyword => {
              return { key: keyword, value: keyword };
            }),
          })
        );
      }

      dispatch(searchActions.setCurrentPage(0));
      dispatch(fetchOffers());
      history.push('/');
    },
    [dispatch, history]
  );

  return (
    <div className={styles.container}>
      {gloablFetchStatus === FETCH_STATUS.FULFILLED &&
        data?.savedSearches &&
        data.savedSearches.length > 0 &&
        data.savedSearches.map(search => {
          const isFavoriteSearch = search.id === favoriteSearch.data?.favoriteSearch?.id;
          return (
            <HorizontalCardWithTitle
              title={search.name}
              titleClassname={styles.title}
              key={`${search.name} ${search.id.toString()}`}
              subtitles={[
                (search.qualifications && search.qualifications?.length > 0) ||
                (search.locations && search.locations.length > 0) ||
                (search.companies && search.companies.length > 0)
                  ? `critères : ${search.qualifications?.map(
                      qualification => ` ${qualification.value}, `
                    )}${search.locations?.map(location => location.label)}`
                  : '',
                `secteur d'activité : ${
                  search.businessActivity !== undefined && search.businessActivity?.length > 0
                    ? nafs[search.businessActivity ?? ''].label
                    : 'N21'
                }`,
              ]}
              className={styles.cardItem}
              onClick={() =>
                onSearch({
                  locations: search.locations,
                  qualifications: search.qualifications,
                  companies: search.companies,
                  keywords: search.keywords,
                })
              }
              leftIcon={
                isFavoriteSearch ? (
                  <FavoriteFill
                    onClick={event => {
                      removeFavoriteSearch.mutate(search.id);
                      event.stopPropagation();
                    }}
                  />
                ) : (
                  <FavoriteLine
                    onClick={event => {
                      saveFavoriteSearch.mutate(search.id);
                      event.stopPropagation();
                    }}
                  />
                )
              }
              rightActions={
                <FavoriteSearchActionButton
                  search={search}
                  favoriteSearchId={favoriteSearch.data?.favoriteSearch?.id}
                />
              }
            />
          );
        })}
      {gloablFetchStatus === FETCH_STATUS.FULFILLED &&
        data !== undefined &&
        (data.savedSearches === undefined || data.savedSearches.length === 0) && (
          <div className={styles.noResult}>
            <img src={glasses} alt="glasses" className={styles.noResultImage} />
            <p className={styles.errorText}>aucune recherche enregistrée pour l'instant</p>
          </div>
        )}
      {gloablFetchStatus === FETCH_STATUS.PENDING &&
        new Array(3)
          .fill(null)
          .map((_, idx) => <HorizontalCardWithTitle.Loading key={`${idx} search card loader`} />)}
      {gloablFetchStatus === FETCH_STATUS.REJECTED && isError && <p>une erreur est survenue</p>}
    </div>
  );
};

export default FavoritesSearchesList;
