import moment from 'moment';

export const presetTimeRanges = {
  '3 derniers jours': moment.duration(3, 'days'),
  'semaine dernière': moment.duration(1, 'week'),
  'dernier mois': moment.duration(1, 'month'),
  '3 derniers mois': moment.duration(3, 'months'),
};

export type PresetTimeRangesKeys = keyof typeof presetTimeRanges;
