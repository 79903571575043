import { Chip } from '@mui/material';
import { AutoSuggest } from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFetchLocationSuggestions } from 'src/Hooks/useFetchLocationSuggestions';
import { getSelectedCriterias } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { Criteria, CRITERIA_TYPE } from 'src/Redux/Search/Types';
import { toFetchStatus } from 'src/Services/ReactQuery';

import styles from './LocationSearchInput.module.scss';

enum HumanReadableLocationType {
  COM = 'commune',
  REG = 'région',
  GREG = 'grande région',
  DEP = 'département',
  BE = "bassin d'emploi",
}

const LocationSearchInput = () => {
  const fetchLocationSuggestions = useFetchLocationSuggestions();
  const dispatch = useDispatch();

  const updateSelectedCriterias = useCallback(
    (type: CRITERIA_TYPE, criterias: Criteria[]) => {
      dispatch(searchActions.updateSelectedCriterias({ type, criterias }));
    },
    [dispatch]
  );

  const locations = useSelector(getSelectedCriterias);

  const selectedLocations = useMemo(
    () =>
      locations
        .filter(criteria => criteria.type === CRITERIA_TYPE.CITIES)
        ?.map(criteria => ({
          label: criteria.criteria.value,
          postCode: criteria.criteria.key,
          locationType: criteria.criteria.subType,
        })),
    [locations]
  );

  return (
    <div className={styles.container}>
      <AutoSuggest
        id="locationCriteria"
        placeholder={'ville, département, bassin… (15 max)'}
        value={selectedLocations}
        onChange={searchResult =>
          updateSelectedCriterias(
            CRITERIA_TYPE.CITIES,
            searchResult.map(location => ({
              key: location.postCode,
              value: location.label,
              subType: location.locationType,
            }))
          )
        }
        searchResults={fetchLocationSuggestions.data ?? []}
        groupBy={location =>
          HumanReadableLocationType[
            (location.locationType as keyof typeof HumanReadableLocationType | undefined) ?? 'COM'
          ]
        }
        onSearch={fetchLocationSuggestions.mutate}
        fetchStatus={toFetchStatus(fetchLocationSuggestions)}
        keyValueExtractor={location => ({
          key: location.postCode,
          value: [location.label, location.postCode].join(' '),
        })}
        minLengthToSearch={3}
        showSelected
        className={styles.autosuggest}
        limitTags={2}
        inputClassName={styles.textInput}
        renderTags={(value, getTagProps) => {
          return value.map((location, index) => {
            const { className, ...rest } = getTagProps({ index });
            return (
              <Chip
                label={
                  location.label.length > 5
                    ? `${location.label.substring(0, 5)}...`
                    : location.label
                }
                {...rest}
                className={classnames(className, styles.chip)}
              />
            );
          });
        }}
      />
    </div>
  );
};

export default LocationSearchInput;
