import { useQuery } from 'react-query';

import useAuth from 'src/Hooks/Authentication/useAuth';
import { OffersService } from 'src/Services/API';

import { QueryKeys } from './types';

export const useFetchSeenOffers = () => {
  const auth = useAuth();
  const userId = auth.user?.profile?.id ?? '';
  return useQuery([QueryKeys.fetchSeenOffers, userId], async () => {
    return await OffersService.offersControllerGetUserOfferHistory();
  });
};
