import classnames from 'classnames';
import React from 'react';

import { lightArrow } from 'src/Assets';

import styles from './PageNavigator.module.scss';
import { Props } from './PageNavigator.types';

const PAGE_NUMBER_TO_SHOW = 5;

const PageNavigator = (props: Props) => {
  const actualPageNumberToShow = Math.min(PAGE_NUMBER_TO_SHOW, props.pageAmount);
  const firstPage = Math.max(
    1,
    Math.min(
      Math.floor(props.selectedPage - PAGE_NUMBER_TO_SHOW / 2),
      props.pageAmount - PAGE_NUMBER_TO_SHOW + 1
    )
  );

  return (
    <div className={styles.container}>
      <img
        src={lightArrow}
        alt="rightArrow"
        className={classnames(styles.arrow, styles.rightArrow, {
          [styles.disabledArrow]: props.selectedPage === 1,
        })}
        onClick={() => {
          if (props.selectedPage !== 1) {
            props.onPageChange(props.selectedPage - 1);
          }
        }}
      />
      {[...Array(actualPageNumberToShow)].map((_, index) => {
        const realPageNumber = index + firstPage;
        return (
          <div
            key={index}
            className={classnames(styles.pageNumber, {
              [styles.selected]: realPageNumber === props.selectedPage,
            })}
            onClick={() => props.onPageChange(realPageNumber)}
          >
            {realPageNumber}
          </div>
        );
      })}
      <img
        src={lightArrow}
        alt="leftArrow"
        className={classnames(styles.arrow, styles.leftArrow, {
          [styles.disabledArrow]: props.selectedPage === props.pageAmount,
        })}
        onClick={() => {
          if (props.selectedPage !== props.pageAmount) {
            props.onPageChange(props.selectedPage + 1);
          }
        }}
      />
    </div>
  );
};

export default PageNavigator;
