import moment, { Moment } from 'moment';
import React, { useState } from 'react';

import { ButtonWithBorder, SolidButton } from 'src/Components/Buttons';
import DateRangePicker from 'src/Components/DateRangePicker';

import styles from './DateRangePopOver.module.scss';
import { Props } from './DateRangePopOver.types';

const DateRangePopOver = (props: Props) => {
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);

  const onRangeChange = (dateRange: [Moment, Moment]) => {
    setStartDate(dateRange[0]);
    setEndDate(dateRange[1]);
  };

  return (
    <div className={styles.container}>
      <div className={styles.dateInputs}>
        <div className={styles.dateColumn}>
          <div className={styles.subtitle}>début de parution</div>
          <div className={styles.dateInput}>{moment(startDate).format('DD/MM/YYYY')}</div>
        </div>
        <div className={styles.dateColumn}>
          <div className={styles.subtitle}>fin de parution</div>
          <div className={styles.dateInput}>
            {endDate && moment(endDate).isValid() ? moment(endDate).format('DD/MM/YYYY') : ''}
          </div>
        </div>
      </div>
      <div className={styles.picker}>
        <DateRangePicker onRangeChange={onRangeChange} startDate={startDate} endDate={endDate} />
      </div>
      <div className={styles.buttonsContainer}>
        <div className={styles.button}>
          <ButtonWithBorder title="annuler" onClick={props.onClose} />
        </div>
        <div className={styles.button}>
          <SolidButton
            title="appliquer"
            onClick={() => {
              if (moment(endDate).isValid()) {
                props.onValidate([startDate, endDate]);
                props.onClose();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DateRangePopOver;
