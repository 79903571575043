import { Auth0ContextInterface, RedirectLoginOptions } from '@auth0/auth0-react';
import React, { createContext, useCallback, useState } from 'react';

import history from 'src/Utils/history';

const initialContext: Pick<
  Auth0ContextInterface,
  | 'getAccessTokenSilently'
  | 'logout'
  | 'isAuthenticated'
  | 'isLoading'
  | 'loginWithRedirect'
  | 'user'
> = {
  isAuthenticated: true,
  isLoading: false,
  user: {
    given_name: process.env.REACT_APP_FAKE_AUTH_USER_GIVEN_NAME,
    family_name: process.env.REACT_APP_FAKE_AUTH_USER_GIVEN_FAMILY_NAME,
    profile: process.env.REACT_APP_FAKE_AUTH_USER_GIVEN_SIID,
    email: process.env.REACT_APP_FAKE_AUTH_USER_GIVEN_EMAIL,
  },
  logout: () => {
    console.log('logout');
  },
  loginWithRedirect: async (options?: RedirectLoginOptions) => {
    console.log('loginWithRedirect', options);
    return Promise.resolve();
  },
  getAccessTokenSilently: () => Promise.resolve('fake_token'),
};

export const MockedAuthContext = createContext<
  Pick<
    Auth0ContextInterface,
    | 'getAccessTokenSilently'
    | 'logout'
    | 'isAuthenticated'
    | 'isLoading'
    | 'loginWithRedirect'
    | 'user'
  >
>(initialContext);

export const MockedAuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const logout = useCallback(() => {
    setIsAuthenticated(false);
    history.push('/login');
  }, []);

  const loginWithRedirect = useCallback((options?: RedirectLoginOptions) => {
    if (
      window.location.pathname === '/login' &&
      options &&
      Object.keys(options).length === 0 &&
      options.constructor === Object
    ) {
      history.push('/');
    }
    setIsAuthenticated(false);
    return Promise.resolve();
  }, []);

  return (
    <MockedAuthContext.Provider
      value={{ ...initialContext, isAuthenticated, logout, loginWithRedirect }}
    >
      {children}
    </MockedAuthContext.Provider>
  );
};
