import React from 'react';
import ContentLoader from 'react-content-loader';

const RightColumnLoader = () => {
  // Pseudo-random width for description line
  const description = [
    342,
    398,
    393,
    336,
    381,
    402,
    321,
    400,
    356,
    321,
    332,
    344,
    403,
    338,
    367,
    394,
    352,
    318,
    368,
    400,
    408,
    336,
    346,
    368,
    328,
    392,
    353,
  ].map((width, index) => (
    <rect key={index} x="16" y={180 + index * 23} rx="4" ry="4" width={width} height="18" />
  ));

  return (
    <ContentLoader height="50rem" uniqueKey="rightColumnLoader">
      <rect x="16" y="16" rx="4" ry="4" width="420" height="32" />
      <rect x="16" y="64" rx="4" ry="4" width="320" height="18" />
      <rect x="16" y="86" rx="4" ry="4" width="60" height="18" />
      <rect x="16" y="130" rx="4" ry="4" width="100" height="18" />

      {description}
    </ContentLoader>
  );
};

export default RightColumnLoader;
