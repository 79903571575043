import { Chip } from '@mui/material';
import {
  AutoSuggest,
  Button,
  DropDown,
  FetchButton,
  Modal,
  PopupActions,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Fav } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { IllusSaveSearch } from 'src/Assets';
import { useSaveSearch } from 'src/Hooks/Consultant/useSaveSearch';
import { EditSearchState } from 'src/Hooks/Modal';
import { useUpdateSearch } from 'src/Hooks/SavedSearch/useUpdateSavedSearch';
import { useFetchCompanySuggestions } from 'src/Hooks/useFetchCompanySuggestions';
import { useFetchLocationSuggestions } from 'src/Hooks/useFetchLocationSuggestions';
import { useFetchQualificationSuggestions } from 'src/Hooks/useFetchQualificationSuggestions';
import { getNafs } from 'src/Redux/Nafs/Selector';
import { getBusinessGroup, getSelectedCriterias } from 'src/Redux/Search/Selectors';
import { CRITERIA_TYPE } from 'src/Redux/Search/Types';
import { EnumTypedLocationLocationType } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';
import { getTypeLabel } from 'src/Utils/transformers';

import styles from './SearchSaveModal.module.scss';

const SearchSaveModal = () => {
  const ref = useRef<PopupActions>(null);
  const history = useHistory();
  const location = useLocation<EditSearchState>();
  const modalParameters = location.state?.modalParameters;
  const trigger = modalParameters?.trigger;
  const searchToModify = modalParameters?.searchToModify;
  const currentBusinessGroup = useSelector(getBusinessGroup);
  const nafs = useSelector(getNafs);
  const locations = useSelector(getSelectedCriterias)
    .filter(criteria => criteria.type === CRITERIA_TYPE.CITIES)
    ?.map(criteria => ({
      label: criteria.criteria.value,
      postCode: criteria.criteria.key,
      locationType: criteria.criteria.subType ?? EnumTypedLocationLocationType.COM,
    }));
  const criteria = useSelector(getSelectedCriterias)
    .filter(
      criteria =>
        criteria.type === CRITERIA_TYPE.QUALIFICATIONS ||
        criteria.type === CRITERIA_TYPE.COMPANIES ||
        criteria.type === CRITERIA_TYPE.KEYWORD
    )
    .map(criteria => ({
      key: criteria.criteria.key,
      value: criteria.criteria.value,
      type: criteria.type,
    }))
    .flat();

  const initialName = searchToModify ? searchToModify?.name : '';

  const initialCriteria = searchToModify
    ? [
        ...((searchToModify?.qualifications ?? []).map(qualification => {
          return {
            key: qualification.key,
            value: qualification.value,
            type: CRITERIA_TYPE.QUALIFICATIONS,
          };
        }) ?? []),
      ]
    : criteria;
  const initialKeywords =
    searchToModify?.keywords ?? []
      ? [
          ...((searchToModify?.keywords ?? []).map(keyword => {
            return { type: CRITERIA_TYPE.KEYWORD, key: CRITERIA_TYPE.KEYWORD, value: keyword };
          }) ?? []),
        ]
      : criteria;

  const initialLocation = searchToModify ? searchToModify?.locations : locations;
  const initialNaf = searchToModify ? searchToModify?.businessActivity : currentBusinessGroup;

  const [searchName, setSearchName] = useState(initialName);
  const [searchLocation, setSearchLocation] = useState(initialLocation ?? []);
  const [searchCriteria, setSearchCriteria] = useState([...initialCriteria, ...initialKeywords]);
  const [searchSector, setSearchSector] = useState(initialNaf ?? '');
  const [keyword, setKeyword] = useState('');

  const fetchLocationSuggestions = useFetchLocationSuggestions();
  const fetchQualificationsCriterias = useFetchQualificationSuggestions();
  const fetchCompanyCriterias = useFetchCompanySuggestions();
  const saveConsultantSearch = useSaveSearch();
  const saveSearchFetchStatus = toFetchStatus(saveConsultantSearch);
  const updateSearchMutation = useUpdateSearch();
  const updateFetchStatus = toFetchStatus(updateSearchMutation);
  const title = searchSector !== '' ? nafs[searchSector].label : 'N21 (par défaut)';
  return (
    <Modal
      ref={ref}
      open
      icon={<IllusSaveSearch />}
      title="sauvegarde de la recherche"
      onOpen={() => {
        setSearchCriteria(initialCriteria ?? []);
        setSearchLocation(initialLocation ?? []);
        setSearchSector(initialNaf ?? '');
        setSearchName(initialName);
      }}
      onClose={() => {
        history.push(location.state.background.pathname);
      }}
      footerActionsLeft={[
        <Button.Tertiary
          key="initialise_button"
          onClick={() => {
            setSearchCriteria([]);
            setSearchLocation([]);
            setSearchSector('');
            setSearchName('');
          }}
        >
          initialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary key="close_save_modal_button" onClick={() => ref.current?.close()}>
          annuler
        </Button.Secondary>,
        <FetchButton
          title="valider"
          key="validate_save_modal_button"
          disabled={searchName === undefined || searchName === ''}
          fetchStatus={searchToModify ? updateFetchStatus : saveSearchFetchStatus}
          onClick={() => {
            searchToModify
              ? updateSearchMutation.mutate({
                  body: {
                    businessActivity: searchSector,
                    keywords: searchCriteria
                      .filter(criteria => criteria.type === CRITERIA_TYPE.KEYWORD)
                      .map(criteria => criteria.value),
                    name: searchName,
                    companies: searchCriteria
                      .filter(criteria => criteria.type === CRITERIA_TYPE.COMPANIES)
                      .map(criteria => criteria.key),
                    locations: searchLocation,
                    qualifications: searchCriteria
                      .filter(criteria => criteria.type === CRITERIA_TYPE.QUALIFICATIONS)
                      .map(criteria => criteria.key),
                  },
                  id: searchToModify.id,
                })
              : saveConsultantSearch.mutate({
                  businessActivity: searchSector,
                  keywords: searchCriteria
                    .filter(criteria => criteria.type === CRITERIA_TYPE.KEYWORD)
                    .map(criteria => criteria.value),
                  name: searchName,
                  companies: searchCriteria
                    .filter(criteria => criteria.type === CRITERIA_TYPE.COMPANIES)
                    .map(criteria => criteria.key),
                  locations: searchLocation,
                  qualifications: searchCriteria
                    .filter(criteria => criteria.type === CRITERIA_TYPE.QUALIFICATIONS)
                    .map(criteria => criteria.key),
                });
          }}
          onSuccess={() => ref.current?.close()}
        />,
      ]}
      trigger={
        trigger ?? (
          <div className={styles.triggerContainer}>
            <Fav />
            <p className={styles.triggerText}>sauvegarder la recherche</p>
          </div>
        )
      }
    >
      <WithLightTitle title="nom de la sauvegarde">
        <TextInput
          value={searchName}
          onChange={e => setSearchName(e.currentTarget.value)}
          placeholder="indiquez le nom de la recherche"
        />
      </WithLightTitle>
      <WithLightTitle className={styles.title} title="lieu(x)">
        <AutoSuggest
          id="locationSaveSearch"
          placeholder="ville, département, bassin… (15 max)"
          showSelected
          minLengthToSearch={3}
          value={searchLocation}
          onChange={searchResult => setSearchLocation(searchResult)}
          searchResults={fetchLocationSuggestions.data ?? []}
          onSearch={fetchLocationSuggestions.mutate}
          fetchStatus={toFetchStatus(fetchLocationSuggestions)}
          keyValueExtractor={location => ({
            key: location.postCode,
            value: [location.label, location.postCode].join(' '),
          })}
          renderTags={(value, getTagProps) => {
            return value.map((location, index) => {
              const { className, ...rest } = getTagProps({ index });
              return (
                <Chip
                  label={
                    location.label.length > 10
                      ? `${location.label.substring(0, 10)}...`
                      : location.label
                  }
                  {...rest}
                  className={classnames(className, styles.chip)}
                />
              );
            });
          }}
        />
      </WithLightTitle>
      <WithLightTitle className={styles.title} title="critères de recherches">
        <AutoSuggest
          id="criteriaSaveSearch"
          placeholder="ex : qualification, nom de société"
          showSelected
          minLengthToSearch={3}
          value={searchCriteria}
          onChange={searchResult => setSearchCriteria(searchResult)}
          searchResults={[
            ...(fetchQualificationsCriterias.data ?? []),
            ...(fetchCompanyCriterias.data ?? []),
            ...[{ type: CRITERIA_TYPE.KEYWORD, key: CRITERIA_TYPE.KEYWORD, value: keyword }],
          ]}
          onSearch={searchText => {
            setKeyword(searchText);
            fetchQualificationsCriterias.mutate(searchText);
            fetchCompanyCriterias.mutate(searchText);
          }}
          fetchStatus={toFetchStatus(fetchQualificationsCriterias, fetchCompanyCriterias)}
          keyValueExtractor={criteria => ({
            key: criteria.key,
            value: criteria.value,
          })}
          groupBy={getTypeLabel}
          renderTags={(value, getTagProps) => {
            return value.map((criteria, index) => {
              const { className, ...rest } = getTagProps({ index });
              return (
                <Chip
                  label={
                    criteria.value.length > 10
                      ? `${criteria.value.substring(0, 10)}...`
                      : criteria.value
                  }
                  {...rest}
                  className={classnames(className, styles.chip)}
                />
              );
            });
          }}
        />
      </WithLightTitle>
      <WithLightTitle title="secteur d'activié" className={styles.title}>
        <DropDown
          childrenClassName={styles.dropDownContainer}
          className={styles.dropdown}
          items={[
            { key: '', value: 'N21 (tout par défaut)' },
            ...Object.keys(nafs).map(key => {
              return { key: key, value: nafs[key].label };
            }),
          ]}
          keyValueExtractor={(item: { key: string; value: string }) => {
            return { key: item.key, value: item.value };
          }}
          onSelectItem={(item: { key: string; value: string }) => {
            item.key === 'all' ? setSearchSector('') : setSearchSector(item.key);
          }}
          selectedItem={{
            key: searchSector ?? '',
            value: title,
          }}
        />
      </WithLightTitle>
    </Modal>
  );
};

export default SearchSaveModal;
