import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { getCurrentOfferId } from 'src/Redux/Offers/Selectors';
import { OfferDetail } from 'src/Redux/Offers/Types';
import { ThunkAPI } from 'src/Redux/RootReducer';
import { CRITERIA_TYPE, ORDER_BY_ELEM } from 'src/Redux/Search/Types';
import { EnumTypedLocationLocationType } from 'src/Services/API';
import {
  getOfferById,
  GetOfferDetailReturn,
  getOffers,
  GetOffersReturn,
} from 'src/Services/API/Offers';
import { transformOfferDetail } from 'src/Utils/transformers';

import { getNafs } from '../Nafs/Selector';
import {
  getBusinessGroup,
  getCurrentPage,
  getEndDate,
  getHideIntermediaryOffers,
  getOrderBySettings,
  getResultsPerPage,
  getSelectedCriterias,
  getStartDate,
} from '../Search/Selectors';

import { offersActions } from './Slice';

export const fetchOffers = createAsyncThunk<GetOffersReturn, number | undefined, ThunkAPI>(
  'offers/fetch',
  async (nextIndex: number | undefined, thunkAPI): Promise<GetOffersReturn> => {
    const state = thunkAPI.getState();
    const startDate = getStartDate(state);
    const endDate = getEndDate(state);
    const businessGroup = getBusinessGroup(state);
    const nafs = getNafs(state);
    const hideIntermediaryOffers = getHideIntermediaryOffers(state);
    const locations = getSelectedCriterias(state)
      .filter(x => x.type === CRITERIA_TYPE.CITIES)
      .map(x => ({
        label: x.criteria.value,
        postCode: x.criteria.key,
        locationType: x.criteria.subType ?? EnumTypedLocationLocationType.COM,
      }));
    const qualifications = getSelectedCriterias(state)
      .filter(x => x.type === CRITERIA_TYPE.QUALIFICATIONS)
      .map(x => x.criteria.value);
    const companies = getSelectedCriterias(state)
      .filter(x => x.type === CRITERIA_TYPE.COMPANIES)
      .map(x => x.criteria.value);
    const keyWords = getSelectedCriterias(state)
      .filter(x => x.type === CRITERIA_TYPE.KEYWORD)
      .map(x => x.criteria.value)
      .join(' ');
    const pageIndex = getCurrentPage(state);
    const pageSize = getResultsPerPage(state);
    const orderBySettings = getOrderBySettings(state);
    const selectedNafs =
      businessGroup !== ''
        ? Object.values(nafs[businessGroup].groups)
            .map(naf => naf.cd_naf_international ?? [])
            .flat(1)
        : [];
    const offers = await getOffers({
      startDate: moment(startDate).toDate(),
      endDate: moment(endDate).toDate(),
      keyword: keyWords,
      pageIndex: pageIndex * pageSize,
      pageSize,
      businessActivities: selectedNafs,
      locations,
      qualifications,
      companies,
      ascending: orderBySettings.ascending,
      orderBy: orderBySettings.orderBy,
      hideIntermediaryOffers: hideIntermediaryOffers,
    });

    if (nextIndex !== undefined) {
      const newId = offers.result[nextIndex]?.id;
      thunkAPI.dispatch(fetchOffer(newId));
      thunkAPI.dispatch(offersActions.setCurrentOfferId(newId));
    }

    return offers;
  }
);

export const fetchOffer = createAsyncThunk<GetOfferDetailReturn, string, ThunkAPI>(
  'offers/fetchById',
  async (offerId: string, thunkAPI): Promise<GetOfferDetailReturn> => {
    thunkAPI.dispatch(offersActions.resetCurrentOffer());
    thunkAPI.dispatch(offersActions.setCurrentOfferId(offerId));
    const result = await getOfferById(offerId);
    if (result.result?.LB_BUSINESS_NAME) {
      thunkAPI.dispatch(fetchOffersFromSameClient(result.result.LB_BUSINESS_NAME));
    }
    return result;
  }
);

export const fetchOffersFromSameClient = createAsyncThunk<OfferDetail[], string, ThunkAPI>(
  'offers/fetchByClient',
  async (clientId: string, thunkAPI): Promise<OfferDetail[]> => {
    const state = thunkAPI.getState();
    const currentOfferId = getCurrentOfferId(state);
    const results = await getOffers({
      companies: [clientId],
      pageIndex: 0,
      pageSize: 15,
      ascending: false,
      orderBy: ORDER_BY_ELEM.DATE,
      startDate: moment().subtract(3, 'months').toDate(),
      endDate: moment().toDate(),
    });
    return results.result
      .filter(offer => offer.id !== currentOfferId)
      .slice(0, 15)
      .map(offer => transformOfferDetail({ offer, offersCount: 0, offerNumber: 0 }));
  }
);
