import React from 'react';

import { Props } from './Title.types';

const Title = ({ title, H, className, style }: Props) => (
  <H className={className} style={style}>
    {title}
  </H>
);

export default Title;
