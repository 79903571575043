import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import { CommentBubble, ThreeDots } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { useFetchOfferComment } from 'src/Hooks/useFetchOfferComment';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/ReactQuery';

import styles from './SearchResult.module.scss';
import { Props } from './SearchResult.types';

const SearchResult = (props: Props) => {
  const history = useHistory();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const offerCommentFetch = useFetchOfferComment({
    offerId: props.offerId,
  });
  const offerCommentFetchStatus = toFetchStatus(offerCommentFetch);
  const [isCommented, setIsCommented] = useState<boolean>(false);

  useEffect(() => {
    if (offerCommentFetchStatus === FETCH_STATUS.FULFILLED) {
      const comment = offerCommentFetch.data.result.comment ?? '';
      setIsCommented(comment !== '');
    }
  }, [offerCommentFetchStatus, offerCommentFetch]);

  return (
    <div
      className={classnames(styles.container, {
        [styles.noTopContainer]: !props.isFirst,
        [styles.seenOffer]: props.wasSeen,
      })}
      onClick={props.onClick}
    >
      <div className={styles.left}>
        <div className={classnames(styles.title, { [styles.seenTitle]: props.wasSeen })}>
          {isCommented && <CommentBubble className={styles.comment_icon} />}
          <div>{props.title}</div>
        </div>
        <div className={classnames(styles.subtitle, { [styles.seenSubtitle]: props.wasSeen })}>
          {props.subtitle}
        </div>
      </div>
      <div className={classnames(styles.right, { [styles.seenRight]: props.wasSeen })}>
        {props.rightFields.map(field => {
          return <p key={field}>{field}</p>;
        })}
      </div>
      <div className={styles.right}>
        <PopupMenu
          position="left top"
          width={280}
          onOpen={() => setIsOpen(true)}
          onClose={() => {
            setIsOpen(false);
          }}
          trigger={
            <Button.Tertiary
              className={classnames(styles.trigger, { [styles.activeTrigger]: isOpen })}
            >
              <ThreeDots className={styles.icon} />
            </Button.Tertiary>
          }
        >
          {offerCommentFetchStatus === FETCH_STATUS.FULFILLED &&
            offerCommentFetch.data !== undefined && (
              <PopupMenu.Item
                keepOpenOnClick
                icon={<CommentBubble />}
                text="annoter"
                onClick={() => {
                  history.push({
                    pathname: '/commentOffer',
                    state: {
                      modalParameters: {
                        offerToModify: props.offerId,
                        comment: offerCommentFetch.data.result,
                      },
                      background: location,
                    },
                  });
                }}
              />
            )}
        </PopupMenu>
      </div>
    </div>
  );
};

export default SearchResult;
