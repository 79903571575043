import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './SearchResultLoader.module.scss';
import { Props } from './SearchResultLoader.types';

const SearchResultLoader = (props: Props) => (
  <div className={styles.container}>
    <ContentLoader height="5.565rem" width="40%" uniqueKey={props.uniqueKey + '_left'}>
      <rect x="3%" y="15" rx="4" ry="4" width="50%" height="18" />
      <rect x="3%" y="45" rx="4" ry="4" width="80%" height="18" />
    </ContentLoader>
    <ContentLoader height="5.565rem" width="30%" uniqueKey={props.uniqueKey + '_right'}>
      <rect x="0" y="20" rx="4" ry="4" width="90%" height="10" />
      <rect x="20%" y="36" rx="4" ry="4" width="50%" height="10" />
      <rect x="20%" y="52" rx="4" ry="4" width="50%" height="10" />
    </ContentLoader>
  </div>
);

export default SearchResultLoader;
