import React from 'react';

import BasicButton from '../BasicButton';

import Styles from './TextButton.module.scss';
import { Props } from './TextButton.types';

const TextButton = (props: Props) => (
  <BasicButton
    style={Styles.style}
    disabledStyle={Styles.disabledStyle}
    titleStyle={Styles.titleStyle}
    titleDisabledStyle={Styles.titleDisabledStyle}
    {...props}
  />
);
export default TextButton;
