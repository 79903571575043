import { useMutation, useQueryClient } from 'react-query';

import useAuth from 'src/Hooks/Authentication/useAuth';
import { OffersService } from 'src/Services/API';

import { MutationKeys, QueryKeys } from './types';
import { useFetchSeenOffers } from './useFetchSeenOffers';

export const useMarkOfferAsRead = () => {
  const queryClient = useQueryClient();
  const { data } = useFetchSeenOffers();
  const auth = useAuth();
  const idUser = auth.user?.profile?.id;

  return useMutation(
    [MutationKeys.markOfferAsRead],
    async (id: string) => {
      queryClient.setQueryData([QueryKeys.fetchSeenOffers, idUser], {
        offerIds: [...(data?.offerIds ?? ([] as string[])), id],
      });

      return await OffersService.offersControllerMarkOfferAsRead({
        id,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.fetchSeenOffers);
      },
    }
  );
};
