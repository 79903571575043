import React from 'react';

import Title from 'src/Components/Title';

import { Props } from './BasicButton.types';

function getButtonContent(props: Props) {
  if (props.titleComponent) {
    return props.titleComponent;
  }

  if (props.H) {
    return (
      <Title
        title={props.title}
        H={props.H}
        className={props.disabled ? props.titleDisabledStyle : props.titleStyle}
      />
    );
  }

  return (
    <p className={props.disabled ? props.titleDisabledStyle : props.titleStyle}>{props.title}</p>
  );
}

const BasicButton = (props: Props) => (
  <button
    onClick={props.onClick}
    className={props.disabled ? props.disabledStyle : props.style}
    disabled={props.disabled}
  >
    {getButtonContent(props)}
  </button>
);

export default BasicButton;
