import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/Redux/RootReducer';

import { FETCH_STATUS } from '../Types';

import { OffersState } from './Types';

const getOffersState = (state: RootState) => state.offers;

export const getOffers = createSelector([getOffersState], (state: OffersState) => state.offers);

export const getNumberOfOffers = createSelector(
  [getOffersState],
  (state: OffersState) => state.numberOfOffers
);

export const getCurrentOffer = createSelector(
  [getOffersState],
  (state: OffersState) => state.currentOffer
);

export const getCurrentOfferIndex = createSelector([getOffersState], (state: OffersState) => {
  if (
    state.offersFetchStatus === FETCH_STATUS.PENDING ||
    state.offersFetchStatus === FETCH_STATUS.REJECTED
  ) {
    return undefined;
  }

  const index = state.offers.findIndex(x => x.id === state.currentOfferId);
  return index === -1 || index === undefined ? undefined : index;
});

export const getCurrentOfferId = createSelector(
  [getOffersState],
  (state: OffersState) => state.currentOfferId
);

export const getCurrentOfferFetchStatus = createSelector(
  [getOffersState],
  (state: OffersState) => state.currentOfferFetchStatus
);

export const getOffersFetchStatus = createSelector(
  [getOffersState],
  (state: OffersState) => state.offersFetchStatus
);

export const getSameClientOffers = createSelector(
  [getOffersState],
  (state: OffersState) => state.sameClientOffers
);

export const getSameClientOffersFetchStatus = createSelector(
  [getOffersState],
  (state: OffersState) => state.sameClientOffersFetchStatus
);
