import React, { Fragment } from 'react';

import KVLine from 'src/Components/KVLine';

import RightColumnLoader from '../RightColumnLoader';

import styles from './PendingOfferDetail.module.scss';

const PendingOfferDetail = () => (
  <>
    <div className={styles.leftColumn}>
      <div className={styles.title}>détails</div>
      {[...Array(10)].map((_, idx) => (
        <Fragment key={idx}>
          <KVLine.Loading /> <div className={styles.detailSpacing} />
        </Fragment>
      ))}
    </div>
    <div className={styles.rightColumn}>
      <RightColumnLoader />
    </div>
  </>
);

export default PendingOfferDetail;
