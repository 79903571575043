import { UseQueryOptions, useQuery } from 'react-query';

import { ConsultantFavorites, ConsultantService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchConsultantFavorites = (
  extraOptions?: UseQueryOptions<ConsultantFavorites, unknown, ConsultantFavorites, QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchConsultantFavorites],
    async () => {
      const consultantData = await ConsultantService.consultantControllerGetConsultantFavorites();
      return consultantData;
    },
    { ...extraOptions }
  );
};
