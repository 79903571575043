import React from 'react';
import ContentLoader from 'react-content-loader';

const KVLineLoader = () => (
  <ContentLoader height="3rem" uniqueKey="loadingCard">
    <rect x="0" y="0" rx="4" ry="4" width="90" height="15" />
    <rect x="0" y="25" rx="4" ry="4" width="240" height="18" />
  </ContentLoader>
);

export default KVLineLoader;
