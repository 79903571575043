import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FETCH_STATUS } from 'src/Redux/Types';

import { fetchOffer, fetchOffers, fetchOffersFromSameClient } from './Thunks';
import { OffersState } from './Types';

const initialState: OffersState = {
  offers: [],
  currentOffer: undefined,
  offersFetchStatus: FETCH_STATUS.FULFILLED,
  currentOfferFetchStatus: FETCH_STATUS.FULFILLED,
  currentOffersRequestId: undefined,
  currentOfferRequestId: undefined,
  numberOfOffers: 0,
  currentOfferId: undefined,
  sameClientOffers: [],
  sameClientOffersFetchStatus: FETCH_STATUS.FULFILLED,
  sameClientOffersRequestId: undefined,
};

export const { reducer: offersReducer, actions: offersActions } = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    reset: state => {
      state.offers = [];
      state.currentOffer = undefined;
      state.currentOfferId = undefined;
      state.currentOffersRequestId = undefined;
      state.currentOfferRequestId = undefined;
      state.numberOfOffers = 0;
    },
    setCurrentOfferId: (state, action: PayloadAction<string | undefined>) => {
      state.currentOfferId = action.payload;
    },
    resetOffers: state => {
      state.offers = [];
    },
    resetCurrentOffer: state => {
      state.currentOffer = undefined;
    },
  },
  extraReducers: builder => {
    // Search offers
    builder.addCase(fetchOffers.pending, (state, action) => {
      state.offersFetchStatus = FETCH_STATUS.PENDING;
      state.currentOffersRequestId = action.meta.requestId;
    });
    builder.addCase(fetchOffers.fulfilled, (state, action) => {
      // we only consider the results of the last emitted request
      if (state.currentOffersRequestId === action.meta.requestId) {
        state.numberOfOffers = action.payload.nbElements;
        state.offers = action.payload.result;
        state.offersFetchStatus = FETCH_STATUS.FULFILLED;
      }
    });
    builder.addCase(fetchOffers.rejected, (state, action) => {
      // we only consider the results of the last emitted request
      if (state.currentOffersRequestId === action.meta.requestId) {
        state.offersFetchStatus = FETCH_STATUS.REJECTED;
      }
    });
    // Fetch offer detail
    builder.addCase(fetchOffer.pending, (state, action) => {
      state.currentOfferFetchStatus = FETCH_STATUS.PENDING;
      state.currentOfferRequestId = action.meta.requestId;
    });
    builder.addCase(fetchOffer.fulfilled, (state, action) => {
      // we only consider the results of the last emitted request
      if (state.currentOfferRequestId === action.meta.requestId) {
        state.currentOffer = action.payload?.result;
        state.currentOfferFetchStatus = FETCH_STATUS.FULFILLED;
      }
    });
    builder.addCase(fetchOffer.rejected, (state, action) => {
      // we only consider the results of the last emitted request
      if (state.currentOfferRequestId === action.meta.requestId) {
        state.currentOfferFetchStatus = FETCH_STATUS.REJECTED;
      }
    });
    // Fetch same client offers
    builder.addCase(fetchOffersFromSameClient.pending, (state, action) => {
      state.sameClientOffersFetchStatus = FETCH_STATUS.PENDING;
      state.sameClientOffersRequestId = action.meta.requestId;
    });
    builder.addCase(fetchOffersFromSameClient.fulfilled, (state, action) => {
      // we only consider the results of the last emitted request
      if (state.sameClientOffersRequestId === action.meta.requestId) {
        state.sameClientOffers = action.payload;
        state.sameClientOffersFetchStatus = FETCH_STATUS.FULFILLED;
      }
    });
    builder.addCase(fetchOffersFromSameClient.rejected, (state, action) => {
      // we only consider the results of the last emitted request
      if (state.sameClientOffersRequestId === action.meta.requestId) {
        state.sameClientOffersFetchStatus = FETCH_STATUS.REJECTED;
      }
    });
  },
});
