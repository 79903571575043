import React from 'react';

import { illusEscalier } from 'src/Assets';
import { ButtonWithBorder } from 'src/Components/Buttons';

import styles from './RejectedOfferDetail.module.scss';
import { Props } from './RejectedOfferDetail.types';

const RejectedOfferDetail = (props: Props) => (
  <div className={styles.errorContainer}>
    <div className={styles.errorTextWhite}>hmmm...</div>
    <div className={styles.errorTextDark}>
      votre serveur ne répond pas. Impossible de récupérer le détail de l’offre
    </div>
    <div className={styles.retryButton}>
      <ButtonWithBorder title="réssayer" onClick={props.onRetry} isWhite />
    </div>
    <img src={illusEscalier} alt={'illustration escalier'} className={styles.errorImage} />
  </div>
);

export default RejectedOfferDetail;
