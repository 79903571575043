import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getNumberOfOffers } from 'src/Redux/Offers/Selectors';
import { fetchOffers } from 'src/Redux/Offers/Thunks';
import { getCurrentPage, getResultsPerPage } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';

import PageNavigator from './PageNavigator.component';

const EnhancedPageNavigator = () => {
  const dispatch = useDispatch();
  const selectedPage = useSelector(getCurrentPage) + 1;
  const pageAmount = Math.ceil(useSelector(getNumberOfOffers) / useSelector(getResultsPerPage));

  const onPageChange = useCallback(
    (page: number) => {
      dispatch(searchActions.setCurrentPage(page - 1));
      dispatch(fetchOffers());
    },
    [dispatch]
  );

  return (
    <PageNavigator
      selectedPage={selectedPage}
      pageAmount={pageAmount}
      onPageChange={onPageChange}
    />
  );
};

export default EnhancedPageNavigator;
