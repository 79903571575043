import { useMutation, UseMutationOptions } from 'react-query';

import { CRITERIA_TYPE } from 'src/Redux/Search/Types';
import { QualificationsService, Suggestion } from 'src/Services/API';

import { MutationKeys } from './types';

export const useFetchQualificationSuggestions = (
  extraOptions?: UseMutationOptions<Suggestion[], unknown, string>
) => {
  return useMutation(
    [MutationKeys.locationSuggestions],
    async (prefix: string) => {
      const suggestions = await QualificationsService.qualificationsControllerGetQualifications({
        prefix,
      });

      return suggestions.slice(0, 15).map(suggestion => ({
        ...suggestion,
        type: CRITERIA_TYPE.QUALIFICATIONS,
      }));
    },
    { ...extraOptions }
  );
};
