import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getNumberOfOffers } from 'src/Redux/Offers/Selectors';
import { getOffers, getOffersFetchStatus } from 'src/Redux/Offers/Selectors';
import { offersActions } from 'src/Redux/Offers/Slice';
import { fetchOffer, fetchOffers } from 'src/Redux/Offers/Thunks';
import { Offer } from 'src/Redux/Offers/Types';
import { getResultsPerPage } from 'src/Redux/Search/Selectors';
import { getOrderBySettings } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { OrderBySettings } from 'src/Redux/Search/Types';

import Results from './Results.component';

const MemoResults = React.memo(Results);

const EnhancedResult = () => {
  const dispatch = useDispatch();

  const results = useSelector(getOffers);
  const resultsPerPage = useSelector(getResultsPerPage);
  const fetchStatus = useSelector(getOffersFetchStatus);

  const fetchOfferDetail = useCallback(
    (offer: Offer) => {
      dispatch(fetchOffer(offer.id));
      dispatch(offersActions.setCurrentOfferId(offer.id));
    },
    [dispatch]
  );

  const updateOrderBySettings = useCallback(
    (settings: OrderBySettings) => {
      dispatch(searchActions.setOrderBySettings(settings));
    },
    [dispatch]
  );

  const resetCurrentOffer = useCallback(() => {
    dispatch(offersActions.resetCurrentOffer());
  }, [dispatch]);

  const offersCount = useSelector(getNumberOfOffers);

  const orderBySettings = useSelector(getOrderBySettings);

  const onSearch = useCallback(() => {
    dispatch(fetchOffers());
  }, [dispatch]);

  return (
    <MemoResults
      results={results}
      fetchOfferDetail={fetchOfferDetail}
      resetCurrentOffer={resetCurrentOffer}
      offersCount={offersCount}
      resultsPerPage={resultsPerPage}
      fetchStatus={fetchStatus}
      orderBySettings={orderBySettings}
      updateOrderBySettings={updateOrderBySettings}
      onSearch={onSearch}
    />
  );
};

export default EnhancedResult;
