import { BaseModal } from '@randstad-lean-mobile-factory/react-components-core';
import { useHistory } from 'react-router-dom';

import OfferDetail from 'src/Components/OfferDetail';

const OfferModal = () => {
  const history = useHistory();
  return (
    <BaseModal
      open
      onClose={() => {
        history.replace('/');
      }}
      lockScroll
    >
      {(close: () => void) => (
        <OfferDetail
          onRequestClose={() => {
            close();
          }}
        />
      )}
    </BaseModal>
  );
};

export default OfferModal;
