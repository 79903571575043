import { SegmentedMenu } from '@randstad-lean-mobile-factory/react-components-core';
import { UnderConstruction } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useState } from 'react';

import styles from './FavoritesPage.module.scss';
import { FavoriteCategories } from './FavoritesPage.types';
import FavoritesSearchesList from './FavoritesSearchesList';
const FavoritesPage = () => {
  const [activeTab, setActiveTab] = useState<FavoriteCategories>(FavoriteCategories.SEARCHES);

  const displayedSection = () => {
    switch (activeTab) {
      case FavoriteCategories.SEARCHES:
        return <FavoritesSearchesList />;
      default:
        return <UnderConstruction />;
    }
  };
  return (
    <div className={styles.container}>
      <SegmentedMenu
        selected={activeTab}
        controls={Object.values(FavoriteCategories)}
        onSelectionChange={setActiveTab}
        keyValueExtractor={tab => ({ key: tab, value: tab })}
        controlItemClassName={styles.controlsLabel}
      />
      {displayedSection()}
    </div>
  );
};

export default FavoritesPage;
