import classnames from 'classnames';
import React from 'react';

import LightTitledContent from 'src/Components/LightTitledContent';

import styles from './KVLine.module.scss';
import { Props } from './KVLine.types';

const KVLine = ({ title, value, emptyMessage, textAlign }: Props) => (
  <LightTitledContent title={title} textAlign={textAlign}>
    <p
      className={classnames(styles.value, {
        [styles.emptyMessage]: value === undefined || value === '',
      })}
      style={{ textAlign: textAlign || 'left' }}
    >
      {value || emptyMessage}
    </p>
  </LightTitledContent>
);

export default KVLine;
