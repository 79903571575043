import { useQuery } from 'react-query';

import { getNafs } from '../Services/API/Nafs';

import { QueryKeys } from './types';

export const useFetchNafs = () => {
  return useQuery([QueryKeys.fetchNafs], async () => {
    return await getNafs();
  });
};
