import classnames from 'classnames';
import React from 'react';

import BasicButton from '../BasicButton';

import styles from './SolidButton.module.scss';
import { Props } from './SolidButton.types';

const SolidButton = (props: Props) => (
  <BasicButton
    style={styles.style}
    disabledStyle={classnames(styles.style, styles.disabled)}
    titleStyle={styles.titleStyle}
    titleDisabledStyle={styles.titleStyle}
    {...props}
  />
);
export default SolidButton;
