import {
  Button,
  FetchButton,
  Modal,
  PopupActions,
  TextArea,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { BDPDeleteWarning } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import useAuth from 'src/Hooks/Authentication/useAuth';
import { CommentOfferState } from 'src/Hooks/Modal';
import { QueryKeys } from 'src/Hooks/types';
import { useUpdateOfferComment } from 'src/Hooks/useUpdateOfferComment';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/ReactQuery';

import styles from './OfferCommentModal.module.scss';

const OfferCommentModal = () => {
  const auth = useAuth();
  const ref = useRef<PopupActions>(null);
  const history = useHistory();
  const location = useLocation<CommentOfferState>();
  const modalParameters = location.state?.modalParameters;
  const offerId = modalParameters.offerToModify;
  const { comment, number, lastUpdate } = modalParameters?.comment ?? {
    comment: '',
    number: 0,
    lastUpdate: '',
  };

  const textMaxLength = 4000;

  const queryClient = useQueryClient();

  const updateOfferCommentMutation = useUpdateOfferComment();
  const updateFetchStatus = toFetchStatus(updateOfferCommentMutation);

  const [newComment, setNewComment] = useState<string>(comment);

  return (
    <Modal
      key={`offer_comment_modal-${offerId}`}
      ref={ref}
      open
      icon={<BDPDeleteWarning className={styles.icon} />}
      title="annotations"
      onClose={() => {
        history.push(location.state.background.pathname);
      }}
      footerActionsLeft={[
        <Button.Tertiary
          key="reset_button"
          onClick={() => {
            setNewComment(comment);
          }}
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary
          key="close_comment_offer_modal_button"
          onClick={() => ref.current?.close()}
        >
          annuler
        </Button.Secondary>,
        <FetchButton
          title="valider"
          key="validate_offer_comment_modal_button"
          disabled={updateFetchStatus !== FETCH_STATUS.FULFILLED}
          fetchStatus={updateFetchStatus}
          onClick={() => {
            updateOfferCommentMutation.mutate({
              body: {
                comments: newComment,
                number: number,
                userName: `${auth.user?.given_name} ${auth.user?.family_name}`,
              },
              id: offerId,
            });
          }}
          onSuccess={() => {
            ref.current?.close();
            queryClient.invalidateQueries([QueryKeys.fetchOfferComment, offerId]);
          }}
        />,
      ]}
      nested
      lockScroll
    >
      <div>
        <ul className={styles.list}>
          <li>
            <WithLightTitle
              title={lastUpdate}
              className={styles.annotation}
              rightTitleComponent={
                <div className={styles.textAreaRightLabel}>
                  {newComment?.length ?? 0}/{textMaxLength} caractères
                </div>
              }
            >
              <div className={styles.bottomContainer}>
                <TextArea
                  className={styles.text}
                  value={newComment}
                  placeholder="une précision, un rappel ou autre..."
                  maxLength={textMaxLength}
                  onChange={event => {
                    setNewComment((event.target as HTMLTextAreaElement).value);
                  }}
                />
              </div>
            </WithLightTitle>
          </li>
        </ul>
      </div>
    </Modal>
  );
};

export default OfferCommentModal;
