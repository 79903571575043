import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { ConsultantService, SavedSearch, SavedSearchCreateParams } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useSaveSearch = (
  extraOptions?: UseMutationOptions<SavedSearch, unknown, SavedSearchCreateParams>
) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MutationKeys.saveConsultantSearch],
    async (body: SavedSearchCreateParams) => {
      const consultantSearch = await ConsultantService.consultantControllerSaveSearch({ body });

      return consultantSearch;
    },
    {
      ...extraOptions,
      onSuccess: () => queryClient.invalidateQueries([QueryKeys.fetchConsultantFavorites]),
    }
  );
};
