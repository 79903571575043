import classnames from 'classnames';
import moment from 'moment';
import React from 'react';

import { cross, leftArrowButton, rightArrowButton, share } from 'src/Assets';
import { FETCH_STATUS } from 'src/Redux/Types';

import Anchor from '../Anchor';
import KVLine from '../KVLine';
import LightTitledContent from '../LightTitledContent';
import Link from '../Link';

import styles from './OfferDetail.module.scss';
import { Props } from './OfferDetail.types';
import PendingOfferDetail from './PendingOfferDetail';
import RejectedOfferDetail from './RejectedOfferDetail';

const OfferDetail = ({
  offer,
  offerFetchStatus,
  onRetry,
  onRequestClose,
  onShare,
  canGoThroughOffers,
  goToNextOffer,
  goToPreviousOffer,
  offersFromSameClient,
  onOfferClick,
  isAuthenticated,
}: Props) => {
  let hostname: string | undefined;

  try {
    hostname = new URL(offer.webSite ?? '').hostname;
    // eslint-disable-next-line no-empty
  } catch (e) {}

  const modalTitle = canGoThroughOffers
    ? `offre ${offer.offerNumber} sur ${offer.offersCount}`
    : 'offre ouverte depuis un lien';

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.topRow}>
          <div
            className={classnames(styles.modalTitle, {
              [styles.disabledText]: !canGoThroughOffers,
            })}
          >
            {modalTitle}
          </div>
          <img src={cross} alt={'cross'} onClick={onRequestClose} className={styles.icon} />
        </div>
        <div className={styles.separator}></div>
        <div className={styles.topRow}>
          <img
            src={share}
            className={styles.topRowIcon}
            alt="partager"
            onClick={() => onShare(offer.id ?? '')}
          />
          <div></div>
          <div className={styles.arrowButtons}>
            <img
              className={classnames({
                [styles.disabledArrow]: !canGoThroughOffers || offer.offerNumber === 1,
              })}
              src={leftArrowButton}
              alt="leftArrowButton"
              onClick={goToPreviousOffer}
            />
            <img
              className={classnames({
                [styles.disabledArrow]:
                  !canGoThroughOffers || offer.offerNumber === offer.offersCount,
              })}
              src={rightArrowButton}
              alt="rightArrowButton"
              onClick={goToNextOffer}
            />
          </div>
        </div>
      </div>
      {offerFetchStatus === FETCH_STATUS.REJECTED ? (
        <RejectedOfferDetail onRetry={onRetry} />
      ) : (
        <div className={styles.bottomContainer}>
          {offerFetchStatus === FETCH_STATUS.PENDING || !isAuthenticated ? (
            <PendingOfferDetail />
          ) : (
            <>
              <div className={styles.leftColumn}>
                <div className={styles.title}>détails</div>
                <KVLine
                  title={'secteur'}
                  value={offer.sector ?? ''}
                  emptyMessage={'secteur inconnu'}
                />
                <div className={styles.detailSpacing} />
                <KVLine
                  title={'type de contrat'}
                  value={offer.contractType ?? ''}
                  emptyMessage={'type de contrat inconnu'}
                />
                <div className={styles.detailSpacing} />
                <KVLine title={'siret'} value={offer.siret ?? ''} emptyMessage={'siret inconnu'} />
                <div className={styles.detailSpacing} />
                <KVLine
                  title={'présence Osmose'}
                  value={offer.isOsmose === undefined ? '' : offer.isOsmose ? 'oui' : 'non'}
                  emptyMessage={"pas d'information"}
                />
                <div className={styles.detailSpacing} />
                <KVLine
                  title={'titre de poste'}
                  value={offer.offerTitle ?? ''}
                  emptyMessage={'titre de poste inconnu'}
                />
                <div className={styles.detailSpacing} />
                <LightTitledContent title={"site web d'origine"}>
                  <Anchor
                    target="_blank"
                    rel="noopener noreferrer"
                    placeholder={'site web inconnu'}
                    href={offer.webSite}
                  >
                    {hostname}
                  </Anchor>
                </LightTitledContent>
                <div className={styles.detailSpacing} />
                <KVLine
                  title={'téléphone'}
                  value={offer.phoneNumber ?? ''}
                  emptyMessage={'téléphone inconnu'}
                />
                <div className={styles.detailSpacing} />
                <LightTitledContent title={'e-mail'}>
                  <Anchor
                    placeholder={'e-mail inconnu'}
                    href={offer.mail && 'mailto:' + offer.mail}
                  >
                    {offer.mail}
                  </Anchor>
                </LightTitledContent>
                <div className={styles.detailSpacing} />
                <KVLine title={'ville'} value={offer.city ?? ''} emptyMessage={'ville inconnue'} />
                <div className={styles.detailSpacing} />
                <KVLine
                  title={'code postal'}
                  value={offer.postCode ?? ''}
                  emptyMessage={'code postal inconnu'}
                />
              </div>
              <div className={styles.rightColumn}>
                <div className={styles.title}>{offer.offerTitle}</div>
                <div className={styles.subtitle}>
                  {offer.publishDate &&
                    'publié le : ' +
                      moment(offer.publishDate).format('DD/MM/YYYY') +
                      (offer.expireDate
                        ? ' - expire le ' + moment(offer.expireDate).format('DD/MM/YYYY')
                        : '')}
                </div>
                <div className={styles.subtitle}>{offer.contractType}</div>
                <div className={styles.descriptionTitle}>{offer.company}</div>
                <div className={styles.description}>
                  {offer.description?.split('<br>').join('\n')}
                </div>
                {offer.company && (
                  <div className={styles.sameClientOffers}>
                    <div className={styles.title}>autres offres de {offer.company}</div>
                    {offersFromSameClient
                      .filter(sameClientOffer => sameClientOffer.offerTitle && sameClientOffer.id)
                      .map(sameClientOffer => (
                        <div className={styles.sameClientOffer}>
                          <Link
                            value={sameClientOffer.offerTitle ?? ''}
                            onClick={() => onOfferClick(sameClientOffer.id ?? '')}
                          />
                        </div>
                      ))}
                    {!offersFromSameClient.length && (
                      <div className={styles.noSameClientOffers}>aucune offre récente</div>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default OfferDetail;
