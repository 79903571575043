import classnames from 'classnames';
import React from 'react';

import BasicButton from '../BasicButton';

import styles from './ButtonWithBorder.module.scss';
import { Props } from './ButtonWithBorder.types';

const ButtonWithBorder = (props: Props) => (
  <BasicButton
    style={classnames(styles.style, { [styles.styleWhite]: props.isWhite })}
    disabledStyle={classnames(styles.style, styles.disabled)}
    titleStyle={classnames(styles.titleStyle, { [styles.titleStyleWhite]: props.isWhite })}
    titleDisabledStyle={classnames(styles.titleStyle, styles.textDisabled)}
    {...props}
  />
);

export default ButtonWithBorder;
