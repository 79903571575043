import moment from 'moment';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchOffers } from 'src/Redux/Offers/Thunks';
import { getEndDate, getPredefinedTimeRange, getStartDate } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { PresetTimeRangesKeys } from 'src/Services/Search';

import DateRangePickerButton from './DateRangePickerButton.component';

const EnhancedDataRangePickerButton = () => {
  const dispatch = useDispatch();
  const startDate = moment(useSelector(getStartDate));
  const endDate = moment(useSelector(getEndDate));
  const updateSelectedPredefinedTime = useCallback(
    (rangeName: PresetTimeRangesKeys) => {
      dispatch(searchActions.setPredefinedTimeRange(rangeName));
      dispatch(searchActions.setCurrentPage(0));
      dispatch(fetchOffers());
    },
    [dispatch]
  );
  const selectedPredefinedTimeRange = useSelector(getPredefinedTimeRange);

  return (
    <DateRangePickerButton
      startDate={startDate}
      endDate={endDate}
      updateSelectedPredefinedTime={updateSelectedPredefinedTime}
      selectedPredefinedTimeRange={selectedPredefinedTimeRange}
    />
  );
};

export default EnhancedDataRangePickerButton;
