import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { fetchOffers } from 'src/Redux/Offers/Thunks';
import { searchActions } from 'src/Redux/Search/Slice';

import TopBar from './TopBar.component';

const EnhancedTopBar = () => {
  const dispatch = useDispatch();

  const onSearch = useCallback(() => {
    dispatch(searchActions.setCurrentPage(0));
    dispatch(fetchOffers());
  }, [dispatch]);
  return <TopBar onSearch={onSearch} />;
};

export default EnhancedTopBar;
