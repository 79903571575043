import { UseMutationOptions, useMutation } from 'react-query';

import { OffersService, UpdateOfferCommentBodyParams } from 'src/Services/API';

import { MutationKeys } from './types';

export const useUpdateOfferComment = (
  extraOptions?: UseMutationOptions<
    string,
    unknown,
    { body: UpdateOfferCommentBodyParams; id: string }
  >
) => {
  return useMutation(
    [MutationKeys.updateSavedSearch],
    async ({ body, id }: { body: UpdateOfferCommentBodyParams; id: string }) => {
      const updatedSearch = await OffersService.offersControllerCommentOffer({
        id,
        body,
      });

      return updatedSearch;
    },
    {
      ...extraOptions,
    }
  );
};
