import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import {
  Eye,
  FavoriteLine,
  ThreeDots,
  Trashcan,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router';

import { useSetFavoriteSearch } from 'src/Hooks/Consultant/useSetFavoriteSearch';
import { useDeleteSearch } from 'src/Hooks/SavedSearch/useDeleteSavedSearch';
import { QueryKeys } from 'src/Hooks/types';

import styles from './FavoriteSearchActionButton.module.scss';
import { Props } from './FavoriteSearchActionButton.types';

const FavoriteSearchActionButton = ({ search, favoriteSearchId }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const deleteSearchMutation = useDeleteSearch();
  const queryClient = useQueryClient();
  const saveFavoriteSearch = useSetFavoriteSearch({
    onSuccess: data => {
      queryClient.invalidateQueries([QueryKeys.fetchConsultantFavorite]);
      queryClient.setQueryData(QueryKeys.fetchConsultantFavorite, () => {
        return data;
      });
    },
  });
  const removeFavoriteSearch = useSetFavoriteSearch({
    onSuccess: data => {
      queryClient.invalidateQueries([QueryKeys.fetchConsultantFavorite]);
      queryClient.setQueryData(QueryKeys.fetchConsultantFavorite, () => {
        return (data.favoriteSearch = undefined);
      });
    },
  });

  return (
    <PopupMenu
      position="left top"
      width={280}
      onOpen={() => setIsOpen(true)}
      onClose={() => {
        setIsOpen(false);
      }}
      trigger={
        <Button.Tertiary className={classnames(styles.trigger, { [styles.activeTrigger]: isOpen })}>
          <ThreeDots className={styles.icon} />
        </Button.Tertiary>
      }
    >
      <PopupMenu.Item
        icon={<Eye />}
        text="consulter la recherche"
        keepOpenOnClick
        onClick={() => {
          history.push({
            pathname: '/editSearch',
            state: { modalParameters: { searchToModify: search }, background: location },
          });
        }}
      />
      <PopupMenu.Item
        text={favoriteSearchId !== search.id ? 'ajouter en favori' : 'supprimer le favori'}
        icon={<FavoriteLine />}
        onClick={() => {
          favoriteSearchId !== search.id
            ? saveFavoriteSearch.mutate(search.id)
            : removeFavoriteSearch.mutate(search.id);
        }}
      />
      <PopupMenu.Item
        text="supprimer la recherche"
        icon={<Trashcan />}
        onClick={() => {
          deleteSearchMutation.mutate({ id: search.id });
        }}
      />
    </PopupMenu>
  );
};
export default FavoriteSearchActionButton;
