import React from 'react';

import useAuth from 'src/Hooks/Authentication/useAuth';

import Login from './Login.component';

const MemoLogin = React.memo(Login);

const EnhancedLogin = () => {
  const auth = useAuth();

  return (
    <MemoLogin loading={auth?.isLoading ?? true} onLoginClick={() => auth?.loginWithRedirect({})} />
  );
};

export default EnhancedLogin;
