import { useEffect } from 'react';
import ReactGA from 'react-ga4';

const useInitializeAnalytics = () => {
  useEffect(() => {
    ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKING_CODE}`);
  }, []);
};

export default useInitializeAnalytics;
