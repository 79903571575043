import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useMutation } from 'react-query';

import { ExportOffersParams, ExportService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useExportSearch = () => {
  return useMutation(
    [MutationKeys.exportSearch],
    async ({ body }: { body: ExportOffersParams }) => {
      return await ExportService.exportControllerInitializeExportOffers({
        body,
      });
    },
    {
      onSuccess: () => {
        toast.success(
          "votre demande a bien été enregistrée, vous recevrez votre export par mail d'ici quelques minutes",
          { autoClose: 5000 }
        );
      },
    }
  );
};
