import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkAPI } from 'src/Redux/RootReducer';
import { getNafs } from 'src/Services/API/Nafs';

import { Nafs } from './Types';

export const fetchNafs = createAsyncThunk<Nafs, undefined, ThunkAPI>(
  'nafs/fetchNAfs',
  async (): Promise<Nafs> => {
    return await getNafs();
  }
);
