import { FETCH_STATUS } from 'src/Redux/Types';

export function toFetchStatus(
  ...queries: { isSuccess: boolean; isError: boolean; isLoading: boolean }[]
) {
  if (queries.find(query => query.isLoading === true)) {
    return FETCH_STATUS.PENDING;
  }
  if (queries.find(query => query.isError === true)) {
    return FETCH_STATUS.REJECTED;
  } else {
    return FETCH_STATUS.FULFILLED;
  }
}

export function mergeFetchStatus(status1: FETCH_STATUS, status2: FETCH_STATUS): FETCH_STATUS {
  if (status1 === FETCH_STATUS.REJECTED || status2 === FETCH_STATUS.REJECTED) {
    return FETCH_STATUS.REJECTED;
  }
  if (status1 === FETCH_STATUS.PENDING || status2 === FETCH_STATUS.PENDING) {
    return FETCH_STATUS.PENDING;
  }
  return FETCH_STATUS.FULFILLED;
}

export function mergeSeveralFetchStatus(statusArray: FETCH_STATUS[]): FETCH_STATUS {
  if (statusArray.includes(FETCH_STATUS.REJECTED)) {
    return FETCH_STATUS.REJECTED;
  }
  if (statusArray.includes(FETCH_STATUS.PENDING)) {
    return FETCH_STATUS.PENDING;
  }
  return FETCH_STATUS.FULFILLED;
}

export function mergeFetchStatusWithFormState(
  fetchStatus: FETCH_STATUS,
  formState: { isSubmitting: boolean; isSubmitSuccessful: boolean; isSubmitted: boolean }
): FETCH_STATUS {
  return formState.isSubmitting
    ? FETCH_STATUS.PENDING
    : !formState.isSubmitSuccessful && formState.isSubmitted
    ? FETCH_STATUS.REJECTED
    : fetchStatus;
}
