import { useMutation, UseMutationOptions } from 'react-query';

import { ConsultantData, ConsultantService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useSetFavoriteSearch = (
  extraOptions?: UseMutationOptions<ConsultantData, unknown, number>
) => {
  return useMutation(
    [MutationKeys.setConsultantFavoriteSearch],
    async (searchId: number) => {
      const consultantSearch = await ConsultantService.consultantControllerSetFavoriteSearch({
        id: searchId,
      });

      return consultantSearch;
    },
    {
      ...extraOptions,
    }
  );
};
