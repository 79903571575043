import { combineReducers } from '@reduxjs/toolkit';

import { nafsReducer } from './Nafs/Slice';
import { offersReducer } from './Offers/Slice';
import { searchReducer } from './Search/Slice';
import { AppDispatch } from './store';

const rootReducer = combineReducers({
  offers: offersReducer,
  nafs: nafsReducer,
  search: searchReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export interface ThunkAPI {
  dispatch: AppDispatch;
  state: RootState;
  extra?: unknown;
  rejectValue?: unknown;
}

export default rootReducer;
