import { DropDown } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import styles from './NafsButton.module.scss';
import { Props } from './NafsButton.types';

const NafsButton = (props: Props) => {
  return (
    <div className={styles.container}>
      <DropDown
        childrenClassName={styles.dropDownContainer}
        className={styles.dropdown}
        items={[
          { key: 'all', value: 'N21 (tout par défaut)' },
          ...Object.keys(props.nafs).map(key => {
            return { key: key, value: props.nafs[key].label };
          }),
        ]}
        keyValueExtractor={(item: { key: string; value: string }) => {
          return { key: item.key, value: item.value };
        }}
        onSelectItem={(item: { key: string; value: string }) => {
          item.key === 'all'
            ? props.onBusinessCategoryUpdate('', '')
            : props.onBusinessCategoryUpdate(item.key, '');
        }}
        selectedItem={{
          key: Object.keys(props.nafs).find(key => props.nafs[key]?.label === props.title) ?? 'all',
          value: props.title,
        }}
      />
    </div>
  );
};

export default NafsButton;
