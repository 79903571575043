import { EnumTypedLocationLocationType } from 'src/Services/API';
import { PresetTimeRangesKeys } from 'src/Services/Search';

export interface Criteria {
  key: string;
  value: string;
  count?: number;
  subType?: EnumTypedLocationLocationType;
}

export enum CRITERIA_TYPE {
  CITIES = 'cities',
  QUALIFICATIONS = 'qualifications',
  COMPANIES = 'companies',
  KEYWORD = 'mot-clés',
}

export enum ORDER_BY_ELEM {
  DATE = 'date',
  QUALIFICATION = 'qualification',
  CONTRACT_TYPE = 'type de contrat',
}

export interface OrderBySettings {
  ascending: boolean;
  orderBy: ORDER_BY_ELEM;
}

export interface SelectedCriteria {
  type: CRITERIA_TYPE;
  criteria: Criteria;
}

export interface SearchState {
  startDate: string;
  endDate: string;
  predefinedTimeRange: PresetTimeRangesKeys | undefined;
  businessGroup: string;
  selectedCriterias: SelectedCriteria[];
  currentPage: number;
  resultsPerPage: number;
  orderBySettings: OrderBySettings;
  hideIntermediaryOffers: boolean;
}
