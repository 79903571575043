import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

import { presetTimeRanges, PresetTimeRangesKeys } from 'src/Services/Search';

import { OrderBySettings, ORDER_BY_ELEM, SearchState, Criteria, CRITERIA_TYPE } from './Types';

const initialHumanReadableStartDate: PresetTimeRangesKeys = '3 derniers mois';

const initialState: SearchState = {
  startDate: moment().subtract(presetTimeRanges[initialHumanReadableStartDate]).toISOString(),
  endDate: moment().toISOString(),
  predefinedTimeRange: initialHumanReadableStartDate,
  businessGroup: '',
  selectedCriterias: [],
  currentPage: 0,
  resultsPerPage: 20,
  orderBySettings: { ascending: true, orderBy: ORDER_BY_ELEM.DATE },
  hideIntermediaryOffers: false,
};

export const { reducer: searchReducer, actions: searchActions } = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setStartDate: (state, action: PayloadAction<string>) => {
      state.predefinedTimeRange = undefined;
      state.startDate = action.payload;
    },
    setEndDate: (state, action: PayloadAction<string>) => {
      state.predefinedTimeRange = undefined;
      state.endDate = action.payload;
    },
    setBusinessGroup: (state, action: PayloadAction<string>) => {
      state.businessGroup = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setResultsPerPage: (state, action: PayloadAction<number>) => {
      state.resultsPerPage = action.payload;
    },
    setOrderBySettings: (state, action: PayloadAction<OrderBySettings>) => {
      state.orderBySettings = action.payload;
    },
    setPredefinedTimeRange: (state, action: PayloadAction<PresetTimeRangesKeys>) => {
      state.predefinedTimeRange = action.payload;
      state.startDate = moment()
        .subtract(presetTimeRanges[state.predefinedTimeRange])
        .toISOString();
      state.endDate = moment().toISOString();
    },
    setHideIntermediaryOffers: (state, action: PayloadAction<boolean>) => {
      state.hideIntermediaryOffers = action.payload;
    },
    addSelectedCriterias: (
      state,
      action: PayloadAction<{ type: CRITERIA_TYPE; criteria: Criteria }>
    ) => {
      if (
        !state.selectedCriterias.some(
          x => x.criteria.key === action.payload.criteria.key && x.type === action.payload.type
        )
      ) {
        state.selectedCriterias.push(action.payload);
      }
    },
    removeSelectedCriterias: (
      state,
      action: PayloadAction<{ type: CRITERIA_TYPE; criteria: Criteria }>
    ) => {
      state.selectedCriterias = state.selectedCriterias.filter(
        x => x.criteria.key !== action.payload.criteria.key || x.type !== action.payload.type
      );
    },
    updateSelectedCriterias: (
      state,
      action: PayloadAction<{ type: CRITERIA_TYPE; criterias: Criteria[] }>
    ) => {
      state.selectedCriterias = [
        ...state.selectedCriterias.filter(criteria => criteria.type !== action.payload.type),
        ...action.payload.criterias.map(criteria => ({
          criteria,
          type: action.payload.type,
        })),
      ];
    },
    resetSelectedCriterias: state => {
      state.selectedCriterias = [];
    },
    resetSideCriterias: state => {
      state.startDate = moment()
        .subtract(presetTimeRanges[initialHumanReadableStartDate])
        .toISOString();
      state.endDate = moment().toISOString();
      state.predefinedTimeRange = initialHumanReadableStartDate;
      state.hideIntermediaryOffers = false;
    },
  },
});
